import styled from 'styled-components';
import { IStyledBottomNavigation } from './types';

export const StyledFooter = styled.div`
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  height: 72px;
  padding: 12px 56px 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
`;

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`;

export const StyledDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 48px;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
`;
export const StyledStepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  border-bottom: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  padding: 16px 0px;
  margin: 12px 0px 0px 0px;
`;

export const StyledEditStepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  border-bottom: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  padding: 16px 0px;
  margin: 12px 0px 0px 0px;

  & > div {
    width: 60%;
  }
`;

export const StyledDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;
  cursor: pointer;
`;
export const StyledBackIcon = styled.img`
  cursor: pointer;
`;

export const StyledBottomNavigation = styled.div<IStyledBottomNavigation>`
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 72px;
  gap: 15px;
  padding: 12px 56px;
  justify-content: ${({ $justify = 'flex-end' }) => $justify};
  align-items: center;
  background-color: ${(p: any) => p.theme.colors.surface.one};
  box-shadow: 0px -2px 8px 0px #3741510f;

  button {
    width: auto;
  }
`;
