import React from 'react';
import {
  StyledCardHeader,
  StyledCardHeaderDetails,
  StyledCardPolicyDetails,
  StyledPolicyCountItem,
  StyledProfileIcon,
  StyledValidationSummaryCard,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { IValidationSummaryCard } from './types';
import { CARD_TYPE_CONFIG } from './constants';

const ValidationSummaryCard: React.FC<IValidationSummaryCard> = ({
  type,
  totalLives,
  policyLives,
}) => {
  const typeConfig = CARD_TYPE_CONFIG[type];
  const showPolicyLives = policyLives.length > 0;
  return (
    <StyledValidationSummaryCard
      $bg={typeConfig.bg}
      $heightLimit={showPolicyLives}
    >
      <StyledCardHeader>
        <StyledCardHeaderDetails>
          <Typography variant="medium">{typeConfig.title}</Typography>
          <Typography variant="title3" color={typeConfig.color}>
            {totalLives} lives
          </Typography>
        </StyledCardHeaderDetails>
        <StyledProfileIcon src={typeConfig.icon} />
      </StyledCardHeader>
      {showPolicyLives && (
        <StyledCardPolicyDetails>
          {policyLives.map((policy, index) => (
            <StyledPolicyCountItem key={policy.type + policy.count + index}>
              <Typography variant="small" color="secondary">
                {policy.type}
              </Typography>
              <Typography variant="small" weight="medium">
                {policy.count}
              </Typography>
            </StyledPolicyCountItem>
          ))}
        </StyledCardPolicyDetails>
      )}
    </StyledValidationSummaryCard>
  );
};

export default ValidationSummaryCard;
