import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AddEmployeeGraphic,
  AnnounceIcon,
  CrossOutlineDarkIcon,
  EndorsementsIcon,
  TableContentIcon,
} from '../../../assets/images';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { capitalizeFirstLetter } from '../../../utils/common/Utilities';
import useSegment from '../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
  trackTaskEvent,
} from '../../../utils/segment/utils';
import { extractDate } from '../../pages/Endorsements/ListingPage/utils';
import { IEndoAddEditDeleteModalProps } from '../EndoAddEditDeleteModal/types';
import {
  StyledAddOptionsContainer,
  StyledCrossIcon,
  StyledEndoActionContainer,
  StyledEndoActionImage,
  StyledEndoActions,
  StyledHeader,
  StyledNotificationContainer,
} from './styles';

const EndoAddOptionsModal: FC<IEndoAddEditDeleteModalProps> = ({
  isVisible,
  setIsVisible,
  dateText,
}) => {
  const history = useHistory();
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const location = useLocation();

  const handleCrossIconClick = () => {
    setIsVisible(false);
    trackClick(
      trackClickEvent(
        `X_Add_Options_Modal_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const onSingleAddEmployeeClick = () => {
    trackClick(
      trackClickEvent(
        `Single_Add_Lives_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}_add`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push('/single-add');
  };
  const onSingleAddDependantClick = () => {
    trackClick(
      trackClickEvent(
        `Single_Delete_Lives_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}_add`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push('/add-dependant');
  };

  const onBulkAddClick = () => {
    trackClick(
      trackClickEvent(
        `Add_Lives_${capitalizeFirstLetter(
          location.pathname.substring(1),
        )}_add`,
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push('/bulk-add');
  };

  useEffect(() => {
    if (isVisible)
      trackTask(
        trackTaskEvent(
          `Load_Add_Options_Modal_${capitalizeFirstLetter(
            location.pathname.substring(1),
          )}`,
          location.pathname,
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isModalClosable={false}
    >
      <WithThemeProvider>
        <StyledAddOptionsContainer>
          <StyledHeader>
            <Typography variant="large">
              How would you like to add lives today?
            </Typography>
            <StyledCrossIcon
              src={CrossOutlineDarkIcon}
              onClick={handleCrossIconClick}
            />
          </StyledHeader>
          <StyledNotificationContainer>
            <img src={AnnounceIcon} alt="alert" />
            <Typography variant="small" color="secondary">
              While you can submit your data with us, Loop will share the data
              with the insurer on the{' '}
              <Typography variant="small" color="emerald" weight="bold">
                {extractDate(dateText)} of every month!
              </Typography>
            </Typography>
          </StyledNotificationContainer>
          <StyledEndoActionContainer>
            <StyledEndoActions onClick={onSingleAddEmployeeClick}>
              <StyledEndoActionImage src={AddEmployeeGraphic} />
              <Typography variant="large" weight="medium">
                Add single employee
              </Typography>
              <Typography variant="small" weight="regular" color="secondary">
                Add an employee along with their dependants
              </Typography>
            </StyledEndoActions>
            <StyledEndoActions onClick={onSingleAddDependantClick}>
              <StyledEndoActionImage src={EndorsementsIcon} />
              <Typography variant="large" weight="medium">
                Add single dependant
              </Typography>
              <Typography variant="small" weight="medium" color="secondary">
                of an employee that is not insured yet or a spouse or child
              </Typography>
            </StyledEndoActions>
            <StyledEndoActions onClick={onBulkAddClick}>
              <StyledEndoActionImage src={TableContentIcon} />
              <Typography variant="large" weight="medium">
                Add bulk lives
              </Typography>
              <Typography variant="small" weight="medium" color="secondary">
                Bulk upload multiple employees/dependants through excel
              </Typography>
            </StyledEndoActions>
          </StyledEndoActionContainer>
        </StyledAddOptionsContainer>
      </WithThemeProvider>
    </Modal>
  );
};
export default EndoAddOptionsModal;
