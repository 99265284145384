import {
  FileSelector,
  Form,
  LoopButton,
} from '@loophealth/loop-ui-web-library';
import { ITableHeaderDef } from '@loophealth/loop-ui-web-library/dist/types/atoms/Table/types';
import moment from 'moment';
import LoopApiService from '../../../adaptars/LoopApiService';
import { TrashIcon } from '../../../assets/images';
import {
  deleteUserFileFromBucket,
  groupArrayOfObjectsByKey,
  uploadUserFileToBucket,
} from '../../../utils/common/Utilities';
import {
  IBasicUserAddData,
  IBulkAddData,
  IPolicyRejectedEntries,
  IRejectedAddEntity,
  IUploadedPolicywiseData,
} from '../../pages/Endorsements/BulkAddMembers/types';
import { checkIfSameAddUser } from '../../pages/Endorsements/BulkAddMembers/utils';
import {
  StyledDeleteIcon,
  StyledFileContainer,
  StyledFileName,
} from './styles';
import {
  IIdentifiedMidtermPolicyData,
  IIdentifiedMidtermResponse,
  IIdentifiedMidtermsData,
  IIdentifiedMidtermsResponseEntity,
  IMidtermRequest,
  ITableDataChecks,
  ITableEntry,
} from './types';

export const getMidTermTableColumns = (
  onDateChange: (index: number, newDate: Date) => void,
  onFileUpload: (index: number, file: File) => void,
  onDocumentDeleteClick: (index: number) => void,
  processingState: boolean[],
  isAnyDocumentProcessing: boolean,
  handleFileError: (error: Error) => void,
): ITableHeaderDef<ITableEntry> => {
  return [
    {
      name: 'Employee ID',
      key: 'employee_id',
      width: 200,
    },
    {
      name: 'Name',
      key: 'name',
      width: 250,
    },
    {
      name: 'Date of Marriage',
      key: 'dateOfMarriage',
      width: 250,
      cell: (value, index) => {
        return (
          <Form.DatePicker
            value={value as Date}
            onChange={(newDate) => onDateChange(index, newDate)}
            placeholder="Select a Date"
            maxDate={new Date()}
            iconOrder="left"
            size="small"
            borderless
          />
        );
      },
    },
    {
      name: 'Marriage Certificate',
      key: 'marriageCertificate',
      width: 220,
      cell: (value, index, rowData) => {
        const valueAsFile = value as File;
        const handleOnFileClick = () => {
          const url = URL.createObjectURL(valueAsFile);
          window.open(url, '_blank');
        };
        const isProcessing = !!processingState[index];
        return valueAsFile ? (
          <StyledFileContainer>
            <LoopButton
              variant="text"
              onClick={handleOnFileClick}
              isLoading={isProcessing}
            >
              <StyledFileName>{valueAsFile.name}</StyledFileName>
            </LoopButton>
            {!isProcessing && (
              <StyledDeleteIcon
                src={TrashIcon}
                alt="trash icon"
                onClick={() => onDocumentDeleteClick(index)}
              />
            )}
          </StyledFileContainer>
        ) : (
          <FileSelector
            formats={['.jpg', '.jpeg', '.pdf', '.png']}
            onUpload={(files) =>
              !isProcessing && onFileUpload(index, files[0] as unknown as File)
            }
            onError={handleFileError}
            maxFiles={1}
            maxSize={5}
          >
            <LoopButton
              isLoading={isProcessing}
              variant={isAnyDocumentProcessing ? 'disabled' : 'outline'}
              onClick={() => {}}
              size="small"
            >
              Upload
            </LoopButton>
          </FileSelector>
        );
      },
    },
  ];
};

export const getTableDataChecks = (
  tableData: ITableEntry[],
): ITableDataChecks => {
  let isAnyMidtermCompleted = false;
  let isAllMidtermCompleted = true;
  const missingDataEntries: ITableEntry[] = [];

  tableData.forEach((tableEntry) => {
    const isCompleted = Boolean(
      tableEntry.dateOfMarriage && tableEntry.marriageCertificate,
    );
    isAnyMidtermCompleted = isAnyMidtermCompleted || isCompleted;
    isAllMidtermCompleted = isAllMidtermCompleted && isCompleted;
    if (!isCompleted) missingDataEntries.push(tableEntry);
  });
  return {
    isAllMidtermCompleted,
    isAnyMidtermCompleted,
    missingDataEntries,
  };
};

export const uploadMidtermDocument = async (
  employeeId: string,
  name: string,
  file: File,
): Promise<string> => {
  return await uploadUserFileToBucket(employeeId, name, file);
};

export const deleteMidtermDocument = async (
  filePath?: string,
): Promise<void> => {
  try {
    await deleteUserFileFromBucket(filePath);
  } catch (e) {}
};

export const deleteZombieDocs = async (
  zombieEntries: ITableEntry[],
): Promise<void> => {
  try {
    await Promise.all(
      zombieEntries.map((docEntry) => {
        return deleteMidtermDocument(docEntry.filePath);
      }),
    );
  } catch (e) {}
};

export const generateIdentifyMidTermRequest = (
  uploadedData: IBulkAddData[],
): Record<string, unknown> => {
  const addrequest = uploadedData.map((userData) => {
    return {
      employeeId: userData.employee_id,
      relationship: userData.relationship_to_account_holders,
      coverageStartDate: userData.policy_start_date,
      name: userData.name,
    };
  });

  const midTermRequest = {
    add: addrequest,
    edit: [],
  };
  return midTermRequest;
};

const transformIntoBasicUserData = (
  user: IIdentifiedMidtermsResponseEntity,
): IBasicUserAddData => {
  return {
    employee_id: user.employeeId,
    name: user.name,
    relationship_to_account_holders: user.relationship,
  };
};

export const identifyPolicyWiseMidTermAdditions = async (
  uploadedData: IUploadedPolicywiseData,
  companyId?: string,
): Promise<IIdentifiedMidtermsData> => {
  let policyIdentifiedMidterms: IIdentifiedMidtermsData = {};
  await Promise.all(
    Object.keys(uploadedData).map(async (policyId) => {
      try {
        const midTermRequest = generateIdentifyMidTermRequest(
          uploadedData[policyId],
        );
        const midTermResponse = await LoopApiService.identifyMidterms(
          midTermRequest,
          policyId,
          companyId ?? '',
        );

        // map accepted midterms
        const acceptedMidterms =
          midTermResponse.data.acceptedMidTermAdditions.add;
        const acceptedMidTermAdditions = acceptedMidterms.map(
          (identifiedMidterm) => {
            const user = uploadedData[policyId]?.find((member) =>
              checkIfSameAddUser(
                member,
                transformIntoBasicUserData(identifiedMidterm),
              ),
            );
            return { ...user, isMidtermMember: true };
          },
        );

        // map rejected midterms
        const rejectedMidterms =
          midTermResponse.data.rejectedMidTermAdditions.add;
        const rejectedMidTermAdditions = rejectedMidterms.map(
          (identifiedMidterm) => {
            const user = uploadedData[policyId]?.find((member) =>
              checkIfSameAddUser(
                member,
                transformIntoBasicUserData(identifiedMidterm),
              ),
            );
            return {
              ...user,
              error: `{"midterm":"${identifiedMidterm.error}"}`,
            };
          },
        );
        policyIdentifiedMidterms[policyId] = {
          acceptedMidTermAdditions,
          rejectedMidTermAdditions,
        } as IIdentifiedMidtermPolicyData;
      } catch (e) {}
    }),
  );
  return policyIdentifiedMidterms;
};

export const checkMidtermPresent = (
  identifiedMidterms: IIdentifiedMidtermsData,
) => {
  let isSpouseMidtermPresent = false;
  let isChildMidtermPresent = false;
  Object.keys(identifiedMidterms).forEach((midTermMembers) =>
    identifiedMidterms[midTermMembers].acceptedMidTermAdditions.forEach(
      (member) => {
        if (
          member.relationship_to_account_holders?.toLowerCase() === 'spouse'
        ) {
          isSpouseMidtermPresent = true;
        } else isChildMidtermPresent = true;
      },
    ),
  );
  return {
    isSpouseMidtermPresent,
    isChildMidtermPresent,
  };
};

export const formatTableData = (
  identifiedMidterms: IIdentifiedMidtermsData,
): ITableEntry[] => {
  let tableData: ITableEntry[] = [];
  Object.keys(identifiedMidterms).forEach((policyId) => {
    identifiedMidterms[policyId].acceptedMidTermAdditions.forEach(
      (policyMember) => {
        if (
          policyMember.relationship_to_account_holders.toLowerCase() ===
          'spouse'
        )
          tableData.push({
            employee_id: policyMember.employee_id || '',
            name: policyMember.name,
            policyId,
            dateOfMarriage: null,
            marriageCertificate: null,
            relationship_to_account_holders:
              policyMember.relationship_to_account_holders,
          });
      },
    );
  });
  return tableData;
};

export const generateValidateMidTermsRequest = (
  identifiedMidterms: IBulkAddData[],
  tableData: ITableEntry[],
) => {
  const addUsers: IMidtermRequest[] = [];
  const skippedUsers: IRejectedAddEntity[] = [];
  identifiedMidterms.forEach((addRecord) => {
    const tableRecord = tableData?.find((record) =>
      checkIfSameAddUser(record, addRecord),
    );
    if (tableRecord) {
      if (
        !(
          tableRecord.dateOfMarriage &&
          tableRecord.filePath &&
          tableRecord.marriageCertificate
        )
      ) {
        const errors: Record<string, string> = {};
        if (!tableRecord.dateOfMarriage) {
          errors.dateOfMarriage = 'Date not provided';
        }
        if (!(tableRecord.filePath && tableRecord.marriageCertificate)) {
          errors.marriageCertificate =
            'Marriage Certificate Document not provided';
        }
        return skippedUsers.push({
          ...addRecord,
          error: JSON.stringify(errors),
        });
      }

      const obj = {
        employeeId: addRecord.employee_id,
        relationship: addRecord.relationship_to_account_holders,
        dob: addRecord.date_of_birth,
        dateOfMarriage: moment(tableRecord.dateOfMarriage).format('DD/MM/YYYY'),
        name: addRecord.name,
      };
      addUsers.push(obj);
    } else if (
      addRecord.relationship_to_account_holders.toLowerCase() === 'child'
    ) {
      const obj = {
        employeeId: addRecord.employee_id,
        relationship: addRecord.relationship_to_account_holders,
        dob: addRecord.date_of_birth,
        name: addRecord.name,
      };
      addUsers.push(obj);
    } else {
      skippedUsers.push({
        ...addRecord,
        error: `{"midterm":"Mid term details not provided"}`,
      });
    }
  });

  return { addUsers, skippedUsers };
};

export const validateMidTerms = async (
  identifiedMidterms: IIdentifiedMidtermsData,
  tableData: ITableEntry[],
) => {
  const groupedTableEntries: Record<string, ITableEntry[]> =
    groupArrayOfObjectsByKey(
      tableData,
      (tableEntry: ITableEntry) => tableEntry.policyId,
    );
  const rejectedEntries: IPolicyRejectedEntries = {};
  const updatedAcceptedEntries: IUploadedPolicywiseData = {};

  await Promise.all(
    Object.keys(identifiedMidterms).map(async (policyId) => {
      try {
        const policyRejectedEntries: IRejectedAddEntity[] = [];
        const { skippedUsers, addUsers } = generateValidateMidTermsRequest(
          identifiedMidterms[policyId].acceptedMidTermAdditions,
          groupedTableEntries[policyId],
        );
        // map skipped entries
        policyRejectedEntries.push(...skippedUsers);

        // map rejected midterm addition
        policyRejectedEntries.push(
          ...identifiedMidterms[policyId].rejectedMidTermAdditions,
        );
        if (!addUsers.length) {
          rejectedEntries[policyId] = policyRejectedEntries;
          return;
        }
        const midTermResponse = await LoopApiService.validateMidterms(
          {
            add: addUsers,
            edit: [],
          },
          policyId,
        );
        const acceptedMidterms =
          midTermResponse.data.acceptedMidTermAdditions.add;
        const acceptedMidTermAdditions = acceptedMidterms.map(
          (identifiedMidterm) => {
            const user = identifiedMidterms[
              policyId
            ]?.acceptedMidTermAdditions.find((member) =>
              checkIfSameAddUser(
                member,
                transformIntoBasicUserData(identifiedMidterm),
              ),
            );
            const tableEntry = groupedTableEntries[policyId]?.find(
              (tableEntry) =>
                checkIfSameAddUser(
                  tableEntry,
                  transformIntoBasicUserData(identifiedMidterm),
                ),
            );
            return {
              ...user,
              ...identifiedMidterm,
              kycDocumentURLs: tableEntry?.filePath
                ? [tableEntry.filePath]
                : [],
            } as IRejectedAddEntity;
          },
        );

        // map rejected midterms
        const rejectedMidterms = (
          midTermResponse.data as IIdentifiedMidtermResponse
        ).rejectedMidTermAdditions.add;
        rejectedMidterms.forEach((rejectedMidterm) => {
          const user = identifiedMidterms[
            policyId
          ]?.acceptedMidTermAdditions.find((member) =>
            checkIfSameAddUser(
              member,
              transformIntoBasicUserData(rejectedMidterm),
            ),
          );
          const tableEntry = groupedTableEntries[policyId]?.find((tableEntry) =>
            checkIfSameAddUser(
              tableEntry,
              transformIntoBasicUserData(rejectedMidterm),
            ),
          );
          deleteMidtermDocument(tableEntry?.filePath);
          policyRejectedEntries.push({
            ...user,
            ...rejectedMidterm,
            error: `{"midterm":"${rejectedMidterm.error}"}`,
          } as IRejectedAddEntity);
        });

        rejectedEntries[policyId] = policyRejectedEntries;
        updatedAcceptedEntries[policyId] = acceptedMidTermAdditions;
      } catch (e) {}
    }),
  );
  return {
    updatedAcceptedEntries,
    rejectedEntries,
  };
};

export const updateFilePathForMidTerms = (
  uploadedData: IUploadedPolicywiseData,
  acceptedMidterms: IUploadedPolicywiseData,
) => {
  const updatedUploadedData: IUploadedPolicywiseData = {};
  Object.keys(uploadedData).forEach((policyId) => {
    const updatedData = uploadedData[policyId].map((member) => {
      const identifiedMember = acceptedMidterms[policyId]?.find((midterm) =>
        checkIfSameAddUser(member, midterm),
      );
      return identifiedMember ? identifiedMember : member;
    });
    updatedUploadedData[policyId] = updatedData;
  });
  return updatedUploadedData;
};

export const getRejectedEntriesOnSkip = (
  identifiedMidterms: IIdentifiedMidtermsData,
) => {
  const rejectedEntries: IPolicyRejectedEntries = {};
  Object.keys(identifiedMidterms).forEach((policyId) => {
    const policyRejectedEntries: IRejectedAddEntity[] =
      identifiedMidterms[policyId].rejectedMidTermAdditions;
    const acceptedSkippedMidterms = identifiedMidterms[
      policyId
    ].acceptedMidTermAdditions
      .filter(
        (member) =>
          member.relationship_to_account_holders.toLowerCase() === 'spouse',
      )
      .map((identifiedMidterm) => ({
        ...identifiedMidterm,
        error: `{"dateOfMarriage":"Date not provided","marriageCertificate":"Marriage Certificate Document not provided"}`,
      }));
    policyRejectedEntries.push(...acceptedSkippedMidterms);
    rejectedEntries[policyId] = policyRejectedEntries;
  });
  return rejectedEntries;
};
