import React from 'react';
import { IGenericIconProps } from './types';

const AddIcon: React.FC<IGenericIconProps> = ({
  width = 25,
  height = 24,
  strokeColor = '#025F4C',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12.4961"
      cy="12"
      r="9.25"
      stroke={strokeColor}
      strokeWidth="1.5"
    />
    <path
      d="M12.4961 8V16M16.4961 12H8.49609"
      stroke="#025F4C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export { AddIcon };
