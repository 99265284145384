import moment from 'moment';
import {
  IDependant,
  IEmployeeFormData,
  ISelectedEmployee,
  ISingleAddSelectedPolicy,
} from '../../../../redux/slices/singleAddSlice/types';
import {
  IBulkAddData,
  IUploadedPolicywiseData,
} from '../../../pages/Endorsements/BulkAddMembers/types';
import {
  getMidtermPolicies,
  transformDependentIntoBulkMidtermEntity,
} from '../../../pages/Endorsements/SingleAddDependents/utils';
import { transformDependentIntoBulkEntity } from '../../../pages/Endorsements/SingleAddMembers/utils';
import { familyDefinition } from '../mapping';

export const transformIntoPolicyWiseAddData = (
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  employee: IEmployeeFormData,
  dependants: IDependant[],
  proceedWithoutDeps: boolean,
): IUploadedPolicywiseData => {
  const policies = Object.values(selectedPolicies);
  const policyWiseDetails: IUploadedPolicywiseData = {};

  policies.forEach((policy) => {
    const dependentsMapping = familyDefinition[policy.slabFamilyDefinition];
    if (!dependentsMapping) return;

    const maxAllowed = dependentsMapping.totalDependents + 1;
    let totalCount = 1;
    const mappedDependents = [
      transformDependentIntoBulkEntity(employee, employee, policy),
    ];
    dependentsMapping.dependents?.forEach(({ relation, number }) => {
      if (totalCount >= maxAllowed || proceedWithoutDeps) return; // total overall dependents

      const maxCount = Math.min(maxAllowed - totalCount, number);

      const addedDependents = dependants
        .filter((filledDependent) =>
          relation
            .split('|') // handle parent|parent-in-law
            .includes(filledDependent.relationship?.toLowerCase()!),
        )
        .slice(0, maxCount) // total dependents of this relation
        .map((dependent) =>
          transformDependentIntoBulkEntity(dependent, employee, policy),
        ); // mapping variables for bulk add request

      totalCount += addedDependents.length;
      mappedDependents.push(...addedDependents);
    });
    policyWiseDetails[policy.policyId] = mappedDependents;
  });
  return policyWiseDetails;
};

export const transformIntoPolicyWiseDependantAddData = (
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  employee: ISelectedEmployee | null,
  deps: IDependant[],
): IUploadedPolicywiseData => {
  if (!employee) return {};
  const policies = Object.values(selectedPolicies);
  const policyWiseDetails: IUploadedPolicywiseData = {};
  const dependants = deps.filter((dep) => !dep.isAlreadyEnrolled);
  const midtermPolicies = getMidtermPolicies(selectedPolicies, employee);

  policies.forEach((policy) => {
    const dependentsMapping = familyDefinition[policy.slabFamilyDefinition];
    const alreadyAdded = employee.policyWiseDeps.find(
      (pwd) => pwd.policyId === policy.policyId,
    );
    const selfAdded = alreadyAdded?.family.find(
      (m) => m.relationship === 'self',
    );
    if (!dependentsMapping) return;

    const maxAllowed = dependentsMapping.totalDependents + 1;
    let totalCount = alreadyAdded?.family.length ?? 1;
    const mappedDependents: IBulkAddData[] = alreadyAdded
      ? []
      : [transformDependentIntoBulkEntity(employee, employee, policy)];

    dependentsMapping.dependents?.forEach(({ relation, number }) => {
      if (totalCount >= maxAllowed) return; // total overall dependents

      const maxCount = Math.min(maxAllowed - totalCount, number);

      const addedDependents = dependants
        .filter((filledDependent) =>
          relation.includes(filledDependent.relationship?.toLowerCase()!),
        )
        .slice(0, maxCount) // total dependents of this relation
        .map((dependent) =>
          selfAdded && alreadyAdded
            ? transformDependentIntoBulkMidtermEntity(
                dependent,
                selfAdded,
                employee,
                policy,
                midtermPolicies.includes(policy.policyId),
                moment.unix(alreadyAdded.policyStartDate._seconds).toDate(),
              )
            : transformDependentIntoBulkEntity(employee, employee, policy),
        ); // mapping variables for bulk add request

      totalCount += addedDependents.length;
      mappedDependents.push(...addedDependents);
    });
    if (mappedDependents.length)
      policyWiseDetails[policy.policyId] = mappedDependents;
  });
  return policyWiseDetails;
};
