import { Form } from '@loophealth/loop-ui-web-library';
import React, { useMemo } from 'react';
import { IMobileNumberInput } from './types';

const MobileNumberInput = ({
  error,
  value,
  onError,
  onChange,
  prefix = '+91 | ',
  placeholder = 'Enter mobile number',
  required = false,
}: IMobileNumberInput) => {
  const formattedValue = useMemo(() => {
    const valueString = value.toString();
    const v = valueString.startsWith(prefix)
      ? valueString.substring(prefix.length)
      : valueString;
    return v ? `${prefix}${v}` : v;
  }, [prefix, value]);

  const ph = `${prefix} ${placeholder}`;

  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onError?.('');
    let v = e.target.value;
    if (v.startsWith(prefix)) v = v.substring(prefix.length);
    if (v === '') onChange('');
    else if (v.length > 10) return;
    else if (v.match(/^[0-9]*$/g)) onChange(v);
  };

  const validateInputOnBlur = () => {
    if (!value) {
      if (required) onError?.('Mobile number is required');
    } else if (value.toString().length < 10)
      onError?.('Mobile number should have 10 digits');
  };

  return (
    <div onBlur={validateInputOnBlur}>
      <Form.Input
        type="text"
        name="mobile"
        error={error}
        value={formattedValue}
        onChange={handleMobileChange}
        placeholder={ph}
      />
    </div>
  );
};

export default MobileNumberInput;
