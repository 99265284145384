import styled from 'styled-components';
import { IStyledSectionCard } from './types';

export const StyledSectionCard = styled.div<IStyledSectionCard>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.loopEmerald};
  border-radius: 12px;
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.lightSilver : theme.colors.surface0};
  width: 48%;
`;
export const StyledSectionCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const StyledSectionCardHeaderProfile = styled.div`
  display: flex;
  width: 50%;
  gap: 15px;
  & > div:first-child {
    padding: 0;
  }
`;
export const StyledSectionCardHeaderProfileDetails = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
`;
export const StyledSectionCardHeaderActionButtons = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
export const StyledActionButtonImage = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const StyledSectionCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledSectionCardFooterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  gap: 4px;
`;
