import { ArrowLeftIcon } from '../../../assets/images';
import {
  StyledBackBtnContainer,
  StyledBackIcon,
  StyledOverviewWrapper,
  StyledPolicyOverviewHeader,
  StyledPolicyOverviewHeaderDesc,
  StyledPolicyOverviewHeaderDescSeperator,
  StyledPolicyOverviewHeaderText,
  StyledPolicyOverviewHeaderTitle,
} from './styles';

import {
  Alerts,
  CardIcon,
  DateFormatUtils,
  LoopBadge,
  Shadow,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useHistory } from 'react-router-dom';
import { IPolicyOverview } from './types';
import { getAlertText } from '../PolicyListItem/utils';
import AlertSidebar from '../AlertSidebar';
import { useState } from 'react';
import { mapToPolicyTypeFullForm } from '../../../utils/common/mapping';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import useFetchAlerts from '../TopNavigation/hooks/useFetchAlerts';
import theme from '../../../theme';

const PolicyOverview: React.FC<IPolicyOverview> = ({ policyDetails }) => {
  const history = useHistory();
  const { policyAlertMap } = useFetchAlerts(policyDetails?.id);
  const onBackClick = () => {
    history.goBack();
  };

  const [isAlertsSidebarVisible, setIsAlertsSidebarVisible] = useState(false);
  const handleViewAlert = () => setIsAlertsSidebarVisible(true);
  const policyAlerts = policyAlertMap.get(policyDetails.id);
  const alertText = policyAlerts && getAlertText(policyAlerts);
  const isAlertVisible = !!alertText;

  const formatDate = new DateFormatUtils().formatDateTime;
  const validity =
    formatDate(policyDetails.policyStartDate?._seconds || 0) +
    ' to ' +
    formatDate(policyDetails.policyEndDate?._seconds || 0);
  return (
    <Shadow variant="small">
      <WithThemeProvider>
        {policyAlerts && (
          <AlertSidebar
            setIsAlertsOpen={setIsAlertsSidebarVisible}
            isAlertsOpen={isAlertsSidebarVisible}
            alerts={[policyAlerts]}
          />
        )}
        {isAlertVisible && (
          <Alerts.StatusAlert
            text={
              <Typography variant="small" weight="medium" color="error">
                {alertText}
              </Typography>
            }
            onButtonClick={handleViewAlert}
            alignItems='center'
            buttonText="View Alerts"
            buttonProps={{
              variant: 'outline',
              size: 'small',
              borderColor: theme.colors.planRed,
            }}
          />
        )}
        <StyledOverviewWrapper>
          <StyledBackBtnContainer>
            <StyledBackIcon src={ArrowLeftIcon} onClick={onBackClick} />
            <Typography variant="medium">Policy details</Typography>
          </StyledBackBtnContainer>
          <StyledPolicyOverviewHeader>
            <CardIcon size="58px" imageUrl={policyDetails.insurerLogo} />
            <StyledPolicyOverviewHeaderText>
              <StyledPolicyOverviewHeaderTitle>
                <Typography variant="title3">
                  {mapToPolicyTypeFullForm(policyDetails.policyType)}
                  {(policyDetails.isParentalPolicy && ' (Parental)') ||
                    (policyDetails.isTopUpPolicy && ' (Top Up)')}
                </Typography>
                <LoopBadge
                  variant="overflow"
                  badgeType="grey"
                  text="Validity"
                  textCount={validity as unknown as number}
                />
              </StyledPolicyOverviewHeaderTitle>
              <StyledPolicyOverviewHeaderDesc>
                <Typography variant="small">
                  {policyDetails.insurerName}
                </Typography>
                {policyDetails.nickName && (
                  <>
                    <StyledPolicyOverviewHeaderDescSeperator />
                    <Typography variant="small">
                      {policyDetails.nickName}
                    </Typography>
                  </>
                )}
                {policyDetails.policyNumber && (
                  <>
                    <StyledPolicyOverviewHeaderDescSeperator />
                    <Typography variant="small">
                      Policy No:&nbsp; {policyDetails.policyNumber}
                    </Typography>
                  </>
                )}
              </StyledPolicyOverviewHeaderDesc>
            </StyledPolicyOverviewHeaderText>
          </StyledPolicyOverviewHeader>
        </StyledOverviewWrapper>
      </WithThemeProvider>
    </Shadow>
  );
};

export default PolicyOverview;
