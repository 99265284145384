import styled from 'styled-components';
import { IStyledFormElement } from './types';

export const StyledModalContainer = styled.div<{ $scrollable: boolean }>`
  width: min(850px, 100vw);
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.seafoamGray};
  padding: 0 30px 30px 30px;
  overflow-y: ${(p) => (p.$scrollable ? 'scroll' : 'visible')};
  max-height: ${(p) => (p.$scrollable ? '75vh' : 'auto')};
`;
export const StyledModalBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledBodyWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.seafoamGray};
`;

export const StyledFormElement = styled.div<IStyledFormElement>`
  margin-top: 26px;
  width: 48%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ $hasError }) => ($hasError ? '20px' : '0')};

  input[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
export const StyledFormElementFullWidth = styled(StyledFormElement)`
  width: 100%;
`;
export const StyledGenderElement = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  padding-bottom: 6px;
`;
export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 30px;
`;
export const StyledFooterButton = styled.div`
  width: 30%;
`;
export const StyledFileSelectionContainer = styled.div<{ $errored: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 120px;
  margin-top: ${(p) => (p.$errored ? '20px' : '0')};
`;

export const StyledFileSelectionFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;
