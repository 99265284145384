import { Dialog, Modal } from '@loophealth/loop-ui-web-library';
import { FC } from 'react';
import { ExitErrorIcon } from '../../../../assets/images';
import { toTitleCase } from '../../../../utils/common/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddDependantDetails,
  selectDeletingDependantId,
  setDeletingDependantId,
  setIndividualDependantDetails,
} from '../../../../redux/slices/singleAddSlice';
import { initialDependantDetails } from '../../../../redux/slices/singleAddSlice/initialState';

const EndoAddDeleteDependantModal: FC = () => {
  const dispatch = useDispatch();
  const deletingDependantId = useSelector(selectDeletingDependantId);
  const isVisible = !!deletingDependantId;
  const dependantsList = useSelector(selectAddDependantDetails);
  const dependant = deletingDependantId
    ? dependantsList.find((dependant) => dependant._id === deletingDependantId)
    : null;
  const onClose = () => {
    dispatch(setDeletingDependantId(null));
  };
  const onDeleteClick = () => {
    dispatch(
      setIndividualDependantDetails({
        ...dependant,
        ...initialDependantDetails,
      }),
    );
    onClose();
  };
  if (!deletingDependantId) {
    return null;
  }
  return (
    <Modal isVisible={isVisible} setIsVisible={onClose}>
      <Dialog
        variant="vertical"
        title={`Delete ${toTitleCase(dependant?.relationship || '')}`}
        description="Are you sure you want to delete this member from the policy?"
        icon={ExitErrorIcon}
        primaryButtonText="Delete Member"
        onPrimaryButtonClicked={onDeleteClick}
        onSecondaryButtonClicked={onClose}
        secondaryButtonText="Cancel"
        buttonVariant="error"
      />
    </Modal>
  );
};
export default EndoAddDeleteDependantModal;
