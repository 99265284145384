import { ITableHeader } from '@loophealth/loop-ui-web-library/dist/types/atoms/Table/types';
import { StyledNameColumnWrapper } from '../../../containers/Bulk/MemberSearch/styles';
import { StyledCapitalizedWords } from '../../../containers/ValidationsScreen/styles';
import { FamilyMember } from '../../EmployeeDetails/types';
import { formatMemberName } from '../../../../utils/common/common';

export const SINGLE_FLOW_DEPENDENTS_TABLE_COLUMNS: ITableHeader<FamilyMember>[] =
  [
    {
      name: 'Employee ID',
      key: 'employeeId',
      width: 200,
    },
    {
      name: 'Name',
      key: 'firstName',
      width: 200,
      cell: (_rowValue, _index, rowData) => (
        <StyledNameColumnWrapper>
          <StyledCapitalizedWords>
            {formatMemberName(rowData.firstName, rowData.lastName)}
          </StyledCapitalizedWords>
        </StyledNameColumnWrapper>
      ),
    },
    {
      name: 'Relationship to Employee',
      key: 'relationship',
      width: 250,
      cell: (rowValue) => (
        <StyledCapitalizedWords>{rowValue as string}</StyledCapitalizedWords>
      ),
    },
  ];
