import {
  Alerts,
  Form,
  LoopBadge,
  LoopButton,
  Loader as LoopLoader,
  Modal,
  Shadow,
  Table,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EmployeeApi from '../../../../adaptars/employeeApi';
import { InfoErrorIcon, LoopLogo, SearchIcon } from '../../../../assets/images';
import { selectSingleAddFaqShownUserIds } from '../../../../redux/slices/applicationStateSlice';
import {
  selectPoliciesList,
  selectSelectedCompany,
} from '../../../../redux/slices/hrdRevampRedux';
import {
  selectADPolicyWiseAllowedDependents,
  selectADPolicyWiseDependents,
  selectADSelectedEmployee,
  selectIsExitModalVisible,
  selectSingleAddCurrentStep,
  setAddSelectedPolicies,
  setADPolicyWiseDependents,
  setADSelectedEmployee,
  setExitModalVisible,
  updateCurrentStep,
  updateLastStep,
} from '../../../../redux/slices/singleAddSlice';
import { selectHrUser } from '../../../../redux/slices/usersSlice';
import theme from '../../../../theme';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { parseResponse } from '../../../../utils/common/Utilities';
import { useFetchGlobalSearchData } from '../../../../utils/hooks/business-hooks/useFetchGlobalSearchData';
import useDebouncedValue from '../../../../utils/hooks/useDebouncedValue';
import Backdrop from '../../../atoms/Backdrop';
import Loader from '../../../atoms/Spinner';
import { EmployeeDetails } from '../../../pages/EmployeeDetails/types';
import { StyledUserSelectModalWrapper } from '../../../pages/Endorsements/BulkEditMembers/UserSelectModal/styles';
import { SINGLE_ADD_DEPENDENT_STEPS } from '../../../pages/Endorsements/SingleAddDependents/constants';
import { SINGLE_FLOW_DEPENDENTS_TABLE_COLUMNS } from '../../../pages/Endorsements/SingleDeleteMembers/constants';
import { convertEmployeeSearchResultsToDropdown } from '../../../pages/Endorsements/SingleDeleteMembers/utils';
import EndoSingleExitModal from '../../Bulk/EndoAddExitModal';
import DropdownEmptyState from '../../Bulk/MemberSearch/DropdownEmptyState';
import {
  StyledDropdownLoaderContainer,
  StyledLoopHeader,
  StyledLoopIcon,
  StyledMemberDropdownItem,
  StyledMemberDropdownItemContainer,
  StyledMemberSearch,
  StyledMemberSearchBody,
  StyledMemberSearchContainer,
  StyledMemberSearchHeaderText,
  StyledSearchedDependentsList,
  StyledSearchedDependentsTable,
} from '../../Bulk/MemberSearch/styles';
import SingleAddFaq from '../../Bulk/SingleAddFaq';
import { StyledBottomNavigation } from '../../Bulk/styles';
import {
  checkIfSelfOnlyPolicy,
  convertSearchResultToSelectedEmployee,
  generateSelectedPolicyFromSingleEnrolled,
  getPreferredEmployeeDependents,
} from './utils';

const EmployeeSelectionModal: React.FC = () => {
  const toast = Toast.useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const companyId = useSelector(selectSelectedCompany)?.id || '';
  const selectedEmployee = useSelector(selectADSelectedEmployee);
  const policyWiseAllowedDeps = useSelector(
    selectADPolicyWiseAllowedDependents,
  );
  const policyWiseDeps = useSelector(selectADPolicyWiseDependents);

  const companyPolicies = useSelector(selectPoliciesList).data;
  const currentStep = useSelector(
    selectSingleAddCurrentStep,
  ) as SINGLE_ADD_DEPENDENT_STEPS;

  // faq
  const userId = useSelector(selectHrUser).data?.id ?? '';
  const faqShownUserIds = useSelector(selectSingleAddFaqShownUserIds);
  const isFaqAlreadyShown = faqShownUserIds.includes(userId);

  const [memberSearchText, setMemberSearchText] = useState('');
  const [isDependentsLoading, setIsDependentsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const exitPopupState = useSelector(selectIsExitModalVisible);

  const cancelModalVisible =
    exitPopupState &&
    currentStep === SINGLE_ADD_DEPENDENT_STEPS.SELECT_EMPLOYEE;

  const setIsCancelModalVisible = (value: boolean) =>
    dispatch(setExitModalVisible(value));

  const debouncedSearchString = useDebouncedValue(memberSearchText, 1500);
  const { searchResults, loading: isLoading } = useFetchGlobalSearchData(
    companyId,
    debouncedSearchString,
    false,
    false,
    true,
  );

  const dropdownSearchResults = useMemo(
    () => convertEmployeeSearchResultsToDropdown(searchResults),
    [searchResults],
  );

  const preferredUserDependents = useMemo(
    () => getPreferredEmployeeDependents(policyWiseDeps),
    [policyWiseDeps],
  );

  const isSelfOnlyPolicy = useMemo(
    () => checkIfSelfOnlyPolicy(policyWiseAllowedDeps),
    [policyWiseAllowedDeps],
  );

  const isNextEnabled = !isSelfOnlyPolicy && !isDependentsLoading;

  const setCurrentStep = (step: SINGLE_ADD_DEPENDENT_STEPS) => {
    dispatch(updateLastStep(currentStep));
    dispatch(updateCurrentStep(step));
  };

  const handleResultClick = (value: string) => {
    const selectedResult = searchResults.find((r) => r.userId === value);
    if (!selectedResult?.userPolicies?.length || isDependentsLoading) return;
    if (selectedEmployee?.employeeId === selectedResult.employeeId) return;
    dispatch(
      setADSelectedEmployee(
        convertSearchResultToSelectedEmployee(selectedResult),
      ),
    );
    fetchDependentsForEmployee(selectedResult.userId);
  };

  const fetchDependentsForEmployee = async (employeeUserId: string) => {
    setIsDependentsLoading(true);
    try {
      const [error, response] = await parseResponse(
        EmployeeApi.getEmployeeDetails(employeeUserId, false),
      );
      if (error) throw error;
      const policyWiseDetails: EmployeeDetails[] =
        response?.data?.policyWiseFamilyDetails || [];
      dispatch(setADPolicyWiseDependents(policyWiseDetails));
    } catch (e) {
      toast?.error((e as Error).message);
    } finally {
      setIsDependentsLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsCancelModalVisible(true);
  };

  const confirmModalClose = () => {
    history.goBack();
  };

  const moveToNextPage = () => {
    const isParentalOnly = companyPolicies.find(
      (policy) => policyWiseAllowedDeps[0]?.policyId === policy.id,
    )?.isParentalPolicy;
    const companyHasParental = companyPolicies.some((c) => c.isParentalPolicy);
    if (
      policyWiseAllowedDeps.length > 1 ||
      (companyHasParental && !isParentalOnly)
    )
      setCurrentStep(SINGLE_ADD_DEPENDENT_STEPS.CHOOSE_POLICY);
    else {
      dispatch(
        setAddSelectedPolicies(
          generateSelectedPolicyFromSingleEnrolled(
            policyWiseAllowedDeps[0],
            companyPolicies,
          ),
        ),
      );
      setTimeout(() => setCurrentStep(SINGLE_ADD_DEPENDENT_STEPS.ADD_DEPENDANTS), 10);
    }
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (currentStep === SINGLE_ADD_DEPENDENT_STEPS.SELECT_EMPLOYEE)
      setIsModalVisible(true);
  }, [currentStep]);

  return (
    <>
      <Modal
        isVisible={isModalVisible}
        setIsVisible={handleModalClose}
        disablePadding
        isModalClosable={false}
        closeOnBlur={false}
      >
        <WithThemeProvider>
          <StyledUserSelectModalWrapper>
            <StyledMemberSearch>
              <StyledLoopHeader>
                <StyledLoopIcon src={LoopLogo} />
                <div>
                  <LoopButton
                    variant="outline"
                    textColor="tertiary"
                    backgroundColor={theme.colors.supportingPlaneGreenDark}
                    borderColor="transparent"
                    onClick={handleModalClose}
                    size="small"
                  >
                    Cancel
                  </LoopButton>
                </div>
              </StyledLoopHeader>
              <StyledMemberSearchBody>
                <Shadow variant="nav">
                  <WithThemeProvider>
                    <StyledMemberSearchContainer>
                      <StyledMemberSearchHeaderText>
                        <Typography variant="large" weight="medium">
                          Add Dependants
                        </Typography>
                        <Typography variant="medium" color="secondary">
                          Search for the employee name or ID to add their
                          dependant
                        </Typography>
                      </StyledMemberSearchHeaderText>
                      <div>
                        <Form.Dropdown
                          listItems={
                            debouncedSearchString.length >= 3
                              ? dropdownSearchResults
                              : []
                          }
                          selectedItem={selectedEmployee?.userId || ''}
                          placeholder="Enter employee name or ID"
                          searchable
                          icon={SearchIcon}
                          hideArrow
                          maxHeight="470px"
                          onClick={handleResultClick}
                          onSearchChange={setMemberSearchText}
                          searchFilter={(list) => list}
                          isLoading={isLoading}
                          emptyState="Enter employee name or ID"
                          hidePlaceholderOnFocus
                          keyEventsId="add-dependend-employee-search"
                          noResultsState={(searchText) => {
                            return searchText.length < 3 ? (
                              'Enter at least 3 characters to search'
                            ) : searchText !== debouncedSearchString ? (
                              <StyledDropdownLoaderContainer>
                                <LoopLoader />
                              </StyledDropdownLoaderContainer>
                            ) : (
                              <DropdownEmptyState
                                showSearchText={false}
                                searchText={debouncedSearchString}
                                mode="SINGLE_ADD"
                              />
                            );
                          }}
                          renderItem={(data) => (
                            <StyledMemberDropdownItem>
                              <StyledMemberDropdownItemContainer>
                                <Typography
                                  variant="small"
                                  weight="medium"
                                  color={
                                    data.isExpired ? 'secondary' : 'primary'
                                  }
                                >
                                  {data.name as string}
                                </Typography>
                                <Typography
                                  variant="extraSmall"
                                  color="secondary"
                                >
                                  {data.employeeId as string}
                                </Typography>
                              </StyledMemberDropdownItemContainer>
                              {data.isExpired ? (
                                <LoopBadge
                                  variant="overflow"
                                  badgeType="errorFill"
                                  text={
                                    data.isDeleting
                                      ? 'Deletion In Progress'
                                      : 'Deleted Member'
                                  }
                                  size="small"
                                />
                              ) : !data.isEditable ? (
                                <LoopBadge
                                  variant="overflow"
                                  badgeType="grey"
                                  text="Endorsement In Progress"
                                  size="small"
                                />
                              ) : null}
                            </StyledMemberDropdownItem>
                          )}
                        />
                      </div>
                    </StyledMemberSearchContainer>
                  </WithThemeProvider>
                </Shadow>
                {selectedEmployee ? (
                  isDependentsLoading ? (
                    <Loader />
                  ) : (
                    <StyledSearchedDependentsList>
                      <Typography variant="small" weight="semiBold">
                        Self and Dependant Records for {selectedEmployee.name}
                      </Typography>
                      {isSelfOnlyPolicy && (
                        <Typography
                          variant="small"
                          weight="medium"
                          color="error"
                        >
                          <Alerts.StatusAlert
                            text="You cannot add dependants since this is a self only policy"
                            variant="over"
                            alignItems="center"
                            iconSrc={InfoErrorIcon}
                          />
                        </Typography>
                      )}
                      <Shadow variant="nav">
                        <WithThemeProvider>
                          <StyledSearchedDependentsTable>
                            <Table
                              data={preferredUserDependents}
                              columns={SINGLE_FLOW_DEPENDENTS_TABLE_COLUMNS}
                              fullWidth
                            />
                          </StyledSearchedDependentsTable>
                        </WithThemeProvider>
                      </Shadow>
                    </StyledSearchedDependentsList>
                  )
                ) : null}
              </StyledMemberSearchBody>
            </StyledMemberSearch>
            <Backdrop visible={!isFaqAlreadyShown}>
              <Shadow variant="bottom">
                <StyledBottomNavigation $justify="space-between">
                  <SingleAddFaq
                    isFaqAlreadyShown={isFaqAlreadyShown}
                    userId={userId}
                  />
                  <LoopButton
                    size="medium"
                    variant={isNextEnabled ? 'filled' : 'disabled'}
                    onClick={moveToNextPage}
                  >
                    Add New Dependants
                  </LoopButton>
                </StyledBottomNavigation>
              </Shadow>
            </Backdrop>
          </StyledUserSelectModalWrapper>
        </WithThemeProvider>
      </Modal>
      <EndoSingleExitModal
        isVisible={cancelModalVisible}
        onExit={confirmModalClose}
        onClose={() => setIsCancelModalVisible(false)}
      />
    </>
  );
};

export default EmployeeSelectionModal;
