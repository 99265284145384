import { StyledDependantContainer } from './styles';
import AddDependantModal from '../AddDependantModal';
import AddEditSelfAndSpouseSection from './AddEditSelfAndSpouseSection';
import AddEditChildsSection from './AddEditChildsSection';
import AddEditParentsSection from './AddEditParentsSection';
import { useDependantsHelper } from './useDependantsHelper';
import { useEffect } from 'react';
import EndoAddDeleteDependantModal from '../../Bulk/EndoAddDeleteDependantModal';

const AddDependantDetails = () => {
  const {
    generateInitialDependantsData,
    activeDependants,
    selectedPolicyDetails,
  } = useDependantsHelper();

  useEffect(() => {
    if (activeDependants.length < 2) {
      generateInitialDependantsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolicyDetails]);
  return (
    <StyledDependantContainer>
      <AddEditSelfAndSpouseSection />
      <AddEditChildsSection />
      <AddEditParentsSection />
      <AddDependantModal />
      <EndoAddDeleteDependantModal />
    </StyledDependantContainer>
  );
};

export default AddDependantDetails;
