import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { selectPoliciesList } from '../../../../redux/slices/hrdRevampRedux';
import {
  resetSingleAddState,
  selectActiveDependants,
  selectAddEmployeeDetails,
  selectAddSelectedPolicyDetails,
  selectIsExitModalVisible,
  selectProceedWithoutDeps,
  selectSingleAddCurrentStep,
} from '../../../../redux/slices/singleAddSlice';
import AddDependantDetails from '../../../containers/EndoSingleAdd/AddDependantDetails';
import AddEmployeeDetails from '../../../containers/EndoSingleAdd/AddEmployeeDetails';
import ChoosePolicy from '../../../containers/EndoSingleAdd/ChoosePolicy';
import ReviewCostScreen from '../../../containers/EndoSingleAdd/ReviewCostScreen';
import SingleAddMembersFooter from '../../../containers/EndoSingleAdd/SingleAddMembersFooter';
import SingleAddMembersHeader from '../../../containers/EndoSingleAdd/SingleAddMembersHeader';
import { SINGLE_ADD_BG_COLORS, SINGLE_ADD_STEPS } from './constants';
import { StyledSingleAddContainer } from './styles';
import { transformIntoPolicyWiseAddData } from '../../../containers/EndoSingleAdd/ReviewCostScreen/utils';
import useEstimateEndorsementCost from '../../../containers/Bulk/hooks/useEstimateEndorsementCost';
import EndoSuccessSummary from '../../../containers/Bulk/EndoSuccessSummary';

const SingleAddMembers = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectSingleAddCurrentStep);
  const policiesState = useSelector(selectPoliciesList);
  const allowedPolicies = policiesState.data.filter(
    (policy) => !policy.isTopUpPolicy,
  );
  const isPoliciesLoading =
    policiesState.status === 'loading' && !policiesState.data.length;
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const employeeDetails = useSelector(selectAddEmployeeDetails);
  const dependents = useSelector(selectActiveDependants);
  const exitModalVisible = useSelector(selectIsExitModalVisible);
  const proceedWithoutDeps = useSelector(selectProceedWithoutDeps);

  const isEndoInProgress = useMemo(
    () => !exitModalVisible && currentStep < SINGLE_ADD_STEPS.SUMMARY,
    [currentStep, exitModalVisible],
  );

  const policyWiseDependants = useMemo(
    () =>
      transformIntoPolicyWiseAddData(
        selectedPolicies,
        employeeDetails,
        dependents,
        proceedWithoutDeps,
      ),
    [selectedPolicies, dependents, proceedWithoutDeps, employeeDetails],
  );

  const endoCostDetails = useEstimateEndorsementCost(
    policyWiseDependants,
    currentStep,
    'ADD',
  );

  useEffect(() => {
    dispatch(resetSingleAddState());
  }, []);

  useEffect(() => {
    if (isEndoInProgress) {
      const beforeUnloadCallback = async (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return true;
      };
      window.addEventListener('beforeunload', beforeUnloadCallback);
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadCallback);
      };
    }
  }, [isEndoInProgress]);

  return currentStep === SINGLE_ADD_STEPS.SUMMARY ? (
    <EndoSuccessSummary
      endoCostList={endoCostDetails.endoCostList}
      submittedData={policyWiseDependants}
      mode="ADD"
    />
  ) : (
    <StyledSingleAddContainer $bgColor={SINGLE_ADD_BG_COLORS[currentStep]}>
      <Prompt
        when={isEndoInProgress}
        message="Wait! Are you sure you want to leave? if you leave from here, you'll lose this complete data."
      />
      <SingleAddMembersHeader />
      {currentStep === SINGLE_ADD_STEPS.CHOOSE_POLICY ? (
        <ChoosePolicy
          policiesList={allowedPolicies}
          isLoading={isPoliciesLoading}
        />
      ) : currentStep === SINGLE_ADD_STEPS.ADD_EMPLOYEE ? (
        <AddEmployeeDetails />
      ) : currentStep === SINGLE_ADD_STEPS.ADD_DEPENDANTS ? (
        <AddDependantDetails />
      ) : currentStep === SINGLE_ADD_STEPS.REVIEW_COST ? (
        <ReviewCostScreen endoCostDetails={endoCostDetails} />
      ) : null}
      <SingleAddMembersFooter endoCostDetails={endoCostDetails} />
    </StyledSingleAddContainer>
  );
};

export default SingleAddMembers;
