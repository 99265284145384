import { OPERATION_MODE } from './constants';
import { initialDependantErrors } from '../../../../redux/slices/singleAddSlice/initialState';
import { IDependant } from '../../../../redux/slices/singleAddSlice/types';
import { regexValidations } from '../../../../utils/hooks/useFormInput';
import { IIdentifiedMidtermResponse } from '../../MidTermAdditionModal/types';
import { ISuccessResponse } from '../../../../adaptars/types';
import {
  mandatoryDependantFields,
  mandatorySpouseMidtermFields,
} from '../../../pages/Endorsements/SingleAddMembers/constants';

export const generateLabels = (
  dependantId: string,
  mode: OPERATION_MODE = OPERATION_MODE.ADD,
): {
  modalTitle: string;
  buttonText: string;
  cardButtonText: string;
  relationship: string;
} => {
  const LABELS = {
    self: {
      modalTitle: `${mode} employee`,
      buttonText: `${mode} employee`,
      cardButtonText: `${mode} Employee`,
      relationship: 'self',
    },
    spouse: {
      modalTitle: `${mode} spouse`,
      buttonText: `${mode} spouse`,
      cardButtonText: `${mode} Spouse`,
      relationship: 'spouse',
    },
    c1: {
      modalTitle: `${mode} child`,
      buttonText: `${mode} child`,
      cardButtonText: `${mode} Child 1`,
      relationship: 'child',
    },
    c2: {
      modalTitle: `${mode} child`,
      buttonText: `${mode} child`,
      cardButtonText: `${mode} Child 2`,
      relationship: 'child',
    },
    c3: {
      modalTitle: `${mode} child`,
      buttonText: `${mode} child`,
      cardButtonText: `${mode} Child 3`,
      relationship: 'child',
    },
    c4: {
      modalTitle: `${mode} child`,
      buttonText: `${mode} child`,
      cardButtonText: `${mode} Child 4`,
      relationship: 'child',
    },
    p1: {
      modalTitle: `${mode} parent`,
      buttonText: `${mode} parent`,
      cardButtonText: `${mode} Parent 1`,
      relationship: 'parent',
    },
    p2: {
      modalTitle: `${mode} parent`,
      buttonText: `${mode} parent`,
      cardButtonText: `${mode} Parent 2`,
      relationship: 'parent',
    },
    pil1: {
      modalTitle: `${mode} parent-in-law`,
      buttonText: `${mode} parent-in-law`,
      cardButtonText: `${mode} Parents-in-law 1`,
      relationship: 'parent-in-law',
    },
    pil2: {
      modalTitle: `${mode} parent-in-law`,
      buttonText: `${mode} parent-in-law`,
      cardButtonText: `${mode} Parents-in-law 2`,
      relationship: 'parent-in-law',
    },
  };
  if (!LABELS[dependantId as keyof typeof LABELS]) {
    return {
      modalTitle: `${mode} dependant`,
      buttonText: `${mode} dependant`,
      cardButtonText: `${mode} Dependant`,
      relationship: 'spouse',
    };
  }
  return LABELS[dependantId as keyof typeof LABELS];
};

export const validateDependant = ({
  formData,
  relationship,
  isMidterm,
  isCtcRequired,
}: {
  formData: IDependant;
  relationship: string;
  isMidterm?: boolean;
  isCtcRequired?: boolean;
}): {
  hasError: boolean;
  errorFields: typeof initialDependantErrors;
} => {
  const isEmployee = relationship === 'self';
  const isSpouse = relationship === 'spouse';

  let hasError = false;
  const errorData = { ...initialDependantErrors };
  if (!formData.firstName) {
    errorData.firstName = 'First name is required';
    hasError = true;
  }
  if (!formData.lastName) {
    errorData.lastName = 'Last name is required';
    hasError = true;
  }
  if (!formData.gender) {
    errorData.gender = 'Gender is required';
    hasError = true;
  }
  if (!formData.relationship) {
    errorData.relationship = 'Relationship is required';
    hasError = true;
  }
  if (!formData.dob) {
    errorData.dob = 'Date of birth is required';
    hasError = true;
  }
  if (isSpouse && isMidterm) {
    if (!formData.dateOfMarriage) {
      errorData.dateOfMarriage = 'Date of marriage is required';
      hasError = true;
    }
    if (!formData.kycDocumentURLs) {
      errorData.kycDocumentURLs = 'Marriage Certificate is required';
      hasError = true;
    }
  }
  if (isEmployee) {
    if (!formData.employeeId) {
      errorData.employeeId = 'Employee ID is required';
      hasError = true;
    }
    if (!formData.policyStartDate) {
      errorData.policyStartDate = 'Date of joining is required';
      hasError = true;
    }
  }
  if (formData.mobile) {
    if (formData.mobile.length !== 10) {
      errorData.mobile = 'Mobile number should have 10 digits';
      hasError = true;
    } else if (!regexValidations.phone.test(formData.mobile)) {
      errorData.mobile = 'Mobile number is invalid';
      hasError = true;
    }
  }
  if (formData.email) {
    if (!regexValidations.email.test(formData.email)) {
      errorData.email = 'Please enter a valid email';
      hasError = true;
    }
  }
  if (isCtcRequired && !formData.ctc) {
    errorData.ctc = 'CTC is required';
    hasError = true;
  }

  return {
    hasError,
    errorFields: errorData,
  };
};

export const isSingleAddMidtermValidated = (
  midTermResponse: ISuccessResponse<IIdentifiedMidtermResponse>,
): boolean => {
  const { data } = midTermResponse;
  return data.acceptedMidTermAdditions.add.length > 0;
};

export const checkIfRequiredFieldsFilledForDep = (
  d: IDependant,
  isSpouseMidterm: boolean,
) => {
  return isSpouseMidterm
    ? mandatorySpouseMidtermFields.every((f) => Boolean(d[f]))
    : mandatoryDependantFields.every((f) => Boolean(d[f]));
};
