import styled from 'styled-components';

export const StyledSingleAddHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 56px;
`;
export const StyledSingleAddHeaderTitle = styled.div`
  display: flex;
  gap: 8px;
`;
export const StyledSingleAddHeaderBackButton = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const StyledSingleAddHeaderExitButton = styled.div`
  border-radius: 12px;
  padding: 10px 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;
