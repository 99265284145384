import { Alerts, Typography } from '@loophealth/loop-ui-web-library';
import { InfoErrorIcon } from '../../../../assets/images';
import { IAddAlertError } from './types';
import { useMemo } from 'react';
import {
  StyledAlertWrapper,
  StyledErrorList,
  StyledErrorListItem,
  StyledErrorWrapper,
} from './styles';

const AddAlertError: React.FC<IAddAlertError> = ({ errors }) => {
  const errorData = useMemo(
    () => (Array.isArray(errors) ? errors : [errors]),
    [errors],
  );
  const errorMessage = useMemo(() => {
    return errorData.length === 1 ? (
      <StyledAlertWrapper>
        <Typography variant="small" weight="medium" color="error">
          <Alerts.StatusAlert
            text={errorData[0]}
            variant="over"
            iconSrc={InfoErrorIcon}
          />
        </Typography>
      </StyledAlertWrapper>
    ) : (
      <>
        <StyledAlertWrapper>
          <Typography variant="small" weight="medium" color="error">
            <Alerts.StatusAlert
              text={
                <StyledErrorList>
                  {errorData.map((error: string, index: number) => (
                    <StyledErrorListItem key={index}>
                      {error}
                    </StyledErrorListItem>
                  ))}
                </StyledErrorList>
              }
              variant="over"
              iconSrc={InfoErrorIcon}
            />
          </Typography>
        </StyledAlertWrapper>
      </>
    );
  }, [errorData]);
  if (!errorData.length) return null;
  return <StyledErrorWrapper>{errorMessage}</StyledErrorWrapper>;
};

export default AddAlertError;
