import React, { useEffect, useState } from 'react';
import { parseResponse } from '../../../../utils/common/Utilities';
import LoopApiService from '../../../../adaptars/LoopApiService';
import { ICategorizedData, IEmployeeData, InsuranceStats } from '../types';
import {
  calculateInsuranceCounts,
  getCategorizedList,
  initialStats,
} from '../utils';

const useFetchEmployees = (
  companyId: string,
  policyId: string,
  removeSelf = false,
) => {
  const initialData = {
    Active: [],
    Deleted: [],
    Employees: [],
  };
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [empList, setEmpList] = React.useState<ICategorizedData>(initialData);
  const [empStats, setEmpStats] = React.useState<InsuranceStats>(initialStats);
  useEffect(() => {
    if (policyId && companyId) {
      (async function () {
        toggleLoading(true);
        const [error, result] = await parseResponse(
          LoopApiService.fetchEmployeesListAPI(companyId, policyId),
        );
        toggleLoading(false);
        if (error) {
          console.error(error);
          return;
        }
        const empData = removeSelf
          ? result.data?.users?.filter(
              (member: IEmployeeData) =>
                member.relationship?.toLowerCase() !== 'self',
            )
          : result.data?.users;
        const stats = calculateInsuranceCounts(empData || [], initialStats);
        setEmpStats(stats);
        setEmpList(getCategorizedList(empData || []));
      })();
    }
  }, [policyId, companyId]);

  return { isLoading, empList, empStats };
};

export default useFetchEmployees;
