import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { selectPoliciesList } from '../../../../redux/slices/hrdRevampRedux';
import {
  resetSingleAddState,
  selectAddSelectedPolicyDetails,
  selectActiveDependants,
  selectADSelectedEmployee,
  selectSingleAddCurrentStep,
  selectADPolicyWiseAllowedDependents,
  selectIsExitModalVisible,
} from '../../../../redux/slices/singleAddSlice';
import EndoSuccessSummary from '../../../containers/Bulk/EndoSuccessSummary';
import useEstimateEndorsementCost from '../../../containers/Bulk/hooks/useEstimateEndorsementCost';
import AddDependantDetails from '../../../containers/EndoSingleAdd/AddDependantDetails';
import ChoosePolicy from '../../../containers/EndoSingleAdd/ChoosePolicy';
import EmployeeSelectionModal from '../../../containers/EndoSingleAdd/EmployeeSelectionModal';
import ReviewCostScreen from '../../../containers/EndoSingleAdd/ReviewCostScreen';
import { transformIntoPolicyWiseDependantAddData } from '../../../containers/EndoSingleAdd/ReviewCostScreen/utils';
import SingleAddDependentsFooter from '../../../containers/EndoSingleAdd/SingleAddDependentsFooter';
import SingleAddDependentsHeader from '../../../containers/EndoSingleAdd/SingleAddDependentsHeader';
import { SINGLE_ADD_BG_COLORS } from '../SingleAddMembers/constants';
import { StyledSingleAddContainer } from '../SingleAddMembers/styles';
import { SINGLE_ADD_DEPENDENT_STEPS } from './constants';
import { generateADPolicyList } from './utils';

const SingleAddDependents = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectSingleAddCurrentStep);
  const selectedEmployee = useSelector(selectADSelectedEmployee);
  const companyPolicies = useSelector(selectPoliciesList).data;
  const policyWiseDetails = useSelector(selectADPolicyWiseAllowedDependents);
  const dependants = useSelector(selectActiveDependants);
  const selectedPolicies = useSelector(selectAddSelectedPolicyDetails);
  const isExitPopupVisible = useSelector(selectIsExitModalVisible);
  const allowedPolicyList = useMemo(
    () => generateADPolicyList(companyPolicies, policyWiseDetails),
    [companyPolicies, policyWiseDetails],
  );

  const isEndoInProgress = useMemo(
    () =>
      Boolean(
        !isExitPopupVisible &&
          selectedEmployee &&
          currentStep < SINGLE_ADD_DEPENDENT_STEPS.SUMMARY,
      ),
    [currentStep, selectedEmployee, isExitPopupVisible],
  );

  const policyWiseDependants = useMemo(
    () =>
      transformIntoPolicyWiseDependantAddData(
        selectedPolicies,
        selectedEmployee,
        dependants,
      ),
    [selectedPolicies, selectedEmployee, dependants],
  );

  const endoCostDetails = useEstimateEndorsementCost(
    policyWiseDependants,
    currentStep,
    'ADD',
  );

  useEffect(() => {
    dispatch(resetSingleAddState());
  }, []);

  useEffect(() => {
    if (isEndoInProgress) {
      const beforeUnloadCallback = async (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return true;
      };
      window.addEventListener('beforeunload', beforeUnloadCallback);
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadCallback);
      };
    }
  }, [isEndoInProgress]);

  return currentStep === SINGLE_ADD_DEPENDENT_STEPS.SUMMARY ? (
    <EndoSuccessSummary
      endoCostList={endoCostDetails.endoCostList}
      submittedData={policyWiseDependants}
      mode="ADD"
    />
  ) : (
    <StyledSingleAddContainer $bgColor={SINGLE_ADD_BG_COLORS[currentStep]}>
      <Prompt
        when={isEndoInProgress}
        message="Wait! Are you sure you want to leave? if you leave from here, you'll lose this complete data."
      />
      <SingleAddDependentsHeader />
      {currentStep === SINGLE_ADD_DEPENDENT_STEPS.CHOOSE_POLICY ? (
        <ChoosePolicy policiesList={allowedPolicyList} />
      ) : currentStep === SINGLE_ADD_DEPENDENT_STEPS.ADD_DEPENDANTS ? (
        <AddDependantDetails />
      ) : currentStep === SINGLE_ADD_DEPENDENT_STEPS.REVIEW_COST ? (
        <ReviewCostScreen endoCostDetails={endoCostDetails} />
      ) : null}
      <SingleAddDependentsFooter endoCostDetails={endoCostDetails} />
      <EmployeeSelectionModal />
    </StyledSingleAddContainer>
  );
};

export default SingleAddDependents;
