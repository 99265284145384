import styled from 'styled-components';

export const StyledChoosePolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 56px;
`;

export const StyledChoosePolicyItemContainer = styled.div<{
  $disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(p) =>
    p.$disabled ? p.theme.colors.lightSilver : p.theme.colors.white};
  gap: 12px;
  padding: 24px;
  border-radius: 12px;
  ${(p) => (p.$disabled ? `pointer-events: none;` : '')}
`;
export const StyledPolicyDetailsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
export const StyledPolicyCheckbox = styled.input.attrs({ type: 'checkbox' })`
  accent-color: ${(p) => p.theme.colors.loopEmerald};
  width: 16px;
  height: 16px;
  aspect-ratio: 1;
  border-radius: 4px;
  cursor: pointer;
`;
export const StyledPolicyDetailsHeader = styled.div`
  display: flex;
  gap: 16px;
`;
export const StyledPolicyData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;
export const StyledPolicyType = styled.div``;
export const StyledPolicyDetails = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const StyledDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.seafoamGray};
`;
export const StyledHorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.colors.seafoamGray};
  margin-top: 8px;
  margin-bottom: 16px;
`;
export const StyledChooseSlabSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  gap: 10px;
`;
