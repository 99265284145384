import styled from 'styled-components';

export const StyledChildComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 190px;
  margin-top: 56px;
  height: calc(100vh - 56);
  width: calc(100% - 190px);
  align-items: center;
  justify-content: center;
  overflow: visible;
  /* z-index: 0; */
`;

export const StyledAppContainer = styled.div`
  nav {
    z-index: 1000;
    padding: 0;
  }
`;
export const StyledComponentContainer = styled.div`
  display: flex;
  flex-direction: row;

  nav {
    z-index: 1000;
  }
`;
