import {
  IPolicyListItemSlab,
  IPolicyListItemType,
} from '../../../../redux/slices/hrdRevampRedux/types';
import { ISingleAddSelectedPolicy } from '../../../../redux/slices/singleAddSlice/types';
import {
  formatCurrencyAmountAbbr,
  getPolicyType,
} from '../../../../utils/common/Utilities';
import { PARENTAL_SUPPORTED_FAMILY_STRUCTURES } from '../mapping';

export const converPolicySlabsIntoDropdownItems = (
  policySlabs?: IPolicyListItemSlab[],
) => {
  return (
    policySlabs?.map((slab) => {
      const sumInsured = formatCurrencyAmountAbbr(slab.sumInsured);
      const name = slab.grade
        ? `Sum Insured - ${sumInsured}, Employee Grade - ${slab.grade}`
        : `Sum Insured - ${sumInsured}, Family Structure - ${slab.familyDefinition}`;
      return {
        name,
        value: slab.slabId,
      };
    }) ?? []
  );
};

export const getUniquePolicyChecks = (
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
) => {
  const policies = Object.values(selectedPolicies);
  let selectedGMCPolicyId = null;
  let selectedParentalPolicyId = null;
  const uniquePolices: Record<string, string> = {};

  policies.forEach((p) => {
    const policyType = getPolicyType(
      p.policyType,
      false,
      p.isParentalPolicy,
      p.isOPDPolicy,
    );
    if (
      policyType === 'GMC' &&
      !PARENTAL_SUPPORTED_FAMILY_STRUCTURES.includes(p.slabFamilyDefinition)
    )
      selectedGMCPolicyId = p.policyId;
    if (policyType === 'GMC - Parental') selectedParentalPolicyId = p.policyId;
    uniquePolices[policyType] = p.policyId;
  });
  return {
    selectedGMCPolicyId,
    selectedParentalPolicyId,
    uniquePolices,
  };
};

export const checkIfPolicySelectionDisabled = (
  policy: IPolicyListItemType,
  selectedGMCPolicyId: string | null,
  selectedParentalPolicyId: string | null,
  uniquePolicies: Record<string, string>,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
) => {
  const policyType = getPolicyType(
    policy.policyType,
    false,
    policy.isParentalPolicy,
    policy.isOPDPolicy,
  );
  const familyDef = selectedGMCPolicyId
    ? selectedPolicies[selectedGMCPolicyId]?.slabFamilyDefinition
    : policy.sumAssuredSlabs?.at(-1)?.familyDefinition;
  if (
    policyType === 'GMC' &&
    selectedParentalPolicyId &&
    !PARENTAL_SUPPORTED_FAMILY_STRUCTURES.includes(familyDef ?? '')
  )
    return true;
  if (policyType === 'GMC - Parental' && selectedGMCPolicyId) return true;
  if (uniquePolicies[policyType] && uniquePolicies[policyType] !== policy.id)
    return true;
  return false;
};
