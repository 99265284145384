import {
  LoopButton,
  OutsideClickWrapper,
  Shadow,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  CrossCircleIcon,
  CrossOutlineDarkIcon,
  SmsIcon,
  TickCircleDarkIcon,
} from '../../../../assets/images';
import { addSingleAddFaqShownUserId } from '../../../../redux/slices/applicationStateSlice';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { StyledCloseIcon } from '../../RecommendedCdBreakdownModal/styles';
import {
  StyledSingleAddFaq,
  StyledSingleAddFaqContent,
  StyledSingleAddFaqContentWrapper,
  StyledSingleAddFaqHeader,
  StyledSingleAddFaqHeaderText,
  StyledSingleAddFaqIcon,
  StyledSingleAddFaqIconWrapper,
  StyledSingleAddFaqImportantBadge,
  StyledSingleAddFaqList,
  StyledSingleAddFaqListItem,
  StyledSingleAddFaqNote,
  StyledSingleAddFaqSeperator,
  StyledSingleAddFaqWrapper,
  StyledSingleAddStatusIcon,
} from './styles';
import { ISingleAddFaq, ISingleAddFaqIcon } from './types';

const SingleAddFaqIcon: React.FC<ISingleAddFaqIcon> = ({ src }) => {
  return (
    <Shadow variant="nav">
      <WithThemeProvider>
        <StyledSingleAddFaqIconWrapper>
          <StyledSingleAddFaqIcon src={src} alt="single-add-popup-icons" />
        </StyledSingleAddFaqIconWrapper>
      </WithThemeProvider>
    </Shadow>
  );
};

const SingleAddFaq: React.FC<ISingleAddFaq> = ({
  userId,
  isFaqAlreadyShown,
}) => {
  const dispatch = useDispatch();
  const [isFaqVisible, setIsFaqVisible] = React.useState(!isFaqAlreadyShown);
  const onFaqClick = () => {
    setIsFaqVisible(true);
  };
  const handleClose = () => {
    setIsFaqVisible(false);
    if (!isFaqAlreadyShown) dispatch(addSingleAddFaqShownUserId(userId));
  };
  const onOutsideClick = () => {
    if (isFaqAlreadyShown) handleClose();
  };
  return (
    <WithThemeProvider>
      <StyledSingleAddFaqWrapper>
        <LoopButton onClick={onFaqClick} variant="text" fontWeight="semiBold">
          FAQs
        </LoopButton>
        {isFaqVisible && (
          <Shadow variant="modal">
            <OutsideClickWrapper onClickOutside={onOutsideClick}>
              <WithThemeProvider>
                <StyledSingleAddFaq>
                  <StyledSingleAddFaqContentWrapper>
                    <StyledSingleAddFaqImportantBadge>
                      <Typography
                        variant="extraSmall"
                        weight="medium"
                        color="tertiary"
                      >
                        Important!
                      </Typography>
                    </StyledSingleAddFaqImportantBadge>
                    <StyledSingleAddFaqContent>
                      <StyledSingleAddFaqHeader>
                        <StyledSingleAddFaqHeaderText>
                          <Typography variant="medium" weight="medium">
                            Whom can you add?
                          </Typography>
                        </StyledSingleAddFaqHeaderText>
                        <StyledCloseIcon
                          src={CrossOutlineDarkIcon}
                          onClick={handleClose}
                        />
                      </StyledSingleAddFaqHeader>
                      <StyledSingleAddFaqList>
                        <StyledSingleAddFaqListItem>
                          <StyledSingleAddStatusIcon src={TickCircleDarkIcon} />
                          <Typography variant="small" color="secondary">
                            <Typography
                              variant="small"
                              weight="medium"
                              color="primary"
                            >
                              If employee is insured -&nbsp;
                            </Typography>
                            You can add
                            <Typography
                              variant="small"
                              weight="semiBold"
                              color="secondary"
                            >
                              &nbsp;spouse or children&nbsp;
                            </Typography>
                            as dependants. No other dependants can be added.
                          </Typography>
                        </StyledSingleAddFaqListItem>
                        <StyledSingleAddFaqListItem>
                          <StyledSingleAddStatusIcon src={TickCircleDarkIcon} />
                          <Typography variant="small" color="secondary">
                            <Typography
                              variant="small"
                              weight="medium"
                              color="primary"
                            >
                              If employee is not insured -&nbsp;
                            </Typography>
                            You can add
                            <Typography
                              variant="small"
                              weight="semiBold"
                              color="secondary"
                            >
                              &nbsp;any dependants.
                            </Typography>
                          </Typography>
                        </StyledSingleAddFaqListItem>
                      </StyledSingleAddFaqList>
                    </StyledSingleAddFaqContent>
                    <StyledSingleAddFaqSeperator />
                    <StyledSingleAddFaqNote>
                      <SingleAddFaqIcon src={SmsIcon} />
                      <Typography variant="small">
                        For&nbsp;
                        <Typography variant="small" weight="semiBold">
                          any other dependant addition,
                        </Typography>
                        &nbsp; contact your CSM
                      </Typography>
                    </StyledSingleAddFaqNote>
                  </StyledSingleAddFaqContentWrapper>
                </StyledSingleAddFaq>
              </WithThemeProvider>
            </OutsideClickWrapper>
          </Shadow>
        )}
      </StyledSingleAddFaqWrapper>
    </WithThemeProvider>
  );
};

export default SingleAddFaq;
