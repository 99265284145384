import styled from 'styled-components';

export const StyledEmployeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 12px;
  margin: 0 56px;
`;
export const StyledEmployeeFormContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const StyledEmployeeFormItem = styled.div<{ $errored?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-bottom: ${(p) => (p.$errored ? '40px' : '0')};
`;
export const StyledChipContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const StyledEmployeeExtraErrors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledDeselectModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 350px;
  text-align: center;
  padding-top: 24px;
`;

export const StyledDeselectModalText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledDeselectModalCta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledAlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: start;
`;
