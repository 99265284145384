import styled from 'styled-components';

export const StyledErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;
export const StyledAlertWrapper = styled.div`
  width: 100%;
`;
export const StyledErrorList = styled.ol`
  margin: 0;
`;
export const StyledErrorListItem = styled.li`
  margin-top: 2px;
`;
