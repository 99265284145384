import { PARENTS_RADIO_BUTTONS } from '../constants';

export enum ADD_PARENT_ERROR_TYPE {
  ONE_SIDE_INSURABLE = 'ONE_SIDE_INSURABLE',
}

export const parentsErrorMessageMap = {
  [PARENTS_RADIO_BUTTONS.INSURE_PARENTS]:
    'Please remove parents details to insure parents-in-law. The policy permits insuring either parents or parents-in-law, not both.',
  [PARENTS_RADIO_BUTTONS.INSURE_PARENTS_IN_LAW]:
    'Please remove parents-in-law details to insure parents. The policy permits insuring either parents or parents-in-law, not both.',
};
