import { IRelationship } from './../../components/containers/Bulk/types';
import { IPolicyType } from '../../components/containers/PolicyDetailsCoverage/types';
import { PolicyTypeFullForms } from '../constants';

export const EXTENTION_TO_MIME_TYPE: Record<string, string> = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
};

export const mapToPolicyTypeFullForm = (policyTypeAbbr: IPolicyType) =>
  PolicyTypeFullForms[policyTypeAbbr];

export const formatRelationship = (
  relation: string | undefined,
): IRelationship => {
  const relationship = (relation || '').toString().toLowerCase().trim();
  switch (relationship) {
    case 'mother':
    case 'father':
    case 'parent':
      return 'parent';
    case 'mother-in-law':
    case 'mother in law':
    case 'father in law':
    case 'father-in-law':
    case 'parent-in-law':
      return 'parent-in-law';
    case 'son':
    case 'daughter':
    case 'child':
    case 'kid':
      return 'child';
    case 'wife':
    case 'husband':
    case 'spouse':
      return 'spouse';
    case 'self':
    case 'employee':
      return 'self';
    default:
      return relationship as IRelationship;
  }
};

export const formatGender = (genderString: string | undefined): string => {
  const gender = (genderString || '').toString().toLowerCase().trim();
  switch (gender) {
    case 'm':
    case 'male':
      return 'male';
    case 'f':
    case 'female':
      return 'female';
    default:
      return gender;
  }
};

export const MemberDataSourceMap = new Map([
  ['HRD', 'HRD'],
  ['ENROLMENT_PORTAL', 'Enrolment Portal'],
  ['GENOME', 'Added by CSM'],
  ['HRMS', 'HRMS'],
]);
