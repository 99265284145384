import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  initialDependantErrors,
  initialEmployeeDetails,
  initialEmployeeErrors,
  initialState,
} from './initialState';
import { SINGLE_ADD_STEPS } from '../../../components/pages/Endorsements/SingleAddMembers/constants';
import { ADD_FORM_SUBMISSION_STATUS } from './types';
import { getMidtermPolicies } from '../../../components/pages/Endorsements/SingleAddDependents/utils';

const singleAddSlice = createSlice({
  name: 'singleAdd',
  initialState: initialState,
  reducers: {
    updateCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    updateLastStep: (state, action) => {
      state.lastStep = action.payload;
    },
    setAddSelectedPolicies: (state, action) => {
      state.selectedPolicyDetails = action.payload;
    },
    setSelectedDependant: (state, action) => {
      state.selectedDependant = action.payload;
    },
    setAddDependantDetails: (state, action) => {
      state.dependants = action.payload;
    },
    setIndividualDependantDetails: (state, action) => {
      state.dependants = state.dependants.map((dependant) =>
        dependant._id === action.payload._id ? action.payload : dependant,
      );
    },
    setADSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    setADPolicyWiseDependents: (state, action) => {
      if (state.selectedEmployee)
        state.selectedEmployee.policyWiseDeps = action.payload;
    },
    setAddEmployeeDetails: (state, action) => {
      state.employee = action.payload;
    },
    setAddEmployeeErrors: (state, action) => {
      state.employeeErrors = action.payload;
    },
    setAddEmployeeIdErrors: (state, action) => {
      state.employeeIdErrors = action.payload;
    },
    setAddDependantErrors: (state, action) => {
      state.dependantErrors = action.payload;
    },
    setDeletingDependantId: (state, action) => {
      state.deletingDependantId = action.payload;
    },
    setFormSubmissionStatus: (state, action) => {
      state.formSubmissionStatus = action.payload;
    },
    setExitModalVisible: (state, action) => {
      state.exitModalVisible = action.payload;
    },
    setProceedWithoutDeps: (state, action) => {
      state.proceedWithoutDeps = action.payload;
    },
    resetSingleAddDependantsState: (state) => {
      state.dependants = [];
      state.selectedDependant = null;
      state.dependantErrors = initialDependantErrors;
      state.deletingDependantId = null;
    },
    resetSingleAddState: (state) => {
      state.currentStep = SINGLE_ADD_STEPS.CHOOSE_POLICY;
      state.lastStep = SINGLE_ADD_STEPS.CHOOSE_POLICY;
      state.selectedPolicyDetails = {};
      state.employee = initialEmployeeDetails;
      state.dependants = [];
      state.employeeErrors = initialEmployeeErrors;
      state.employeeIdErrors = null;
      state.dependantErrors = initialDependantErrors;
      state.selectedDependant = null;
      state.selectedEmployee = null;
      state.deletingDependantId = null;
      state.formSubmissionStatus = ADD_FORM_SUBMISSION_STATUS.IDLE;
      state.exitModalVisible = false;
    },
  },
  extraReducers: {
    'common/cleanup': (state) => {
      state.currentStep = SINGLE_ADD_STEPS.CHOOSE_POLICY;
      state.lastStep = SINGLE_ADD_STEPS.CHOOSE_POLICY;
      state.selectedPolicyDetails = {};
      state.employee = initialEmployeeDetails;
      state.dependants = [];
      state.employeeErrors = initialEmployeeErrors;
      state.employeeIdErrors = null;
      state.dependantErrors = initialDependantErrors;
      state.selectedDependant = null;
      state.selectedEmployee = null;
      state.deletingDependantId = null;
      state.formSubmissionStatus = ADD_FORM_SUBMISSION_STATUS.IDLE;
      state.exitModalVisible = false;
    },
  },
});

export const selectSingleAddCurrentStep = (state: RootState) =>
  state.singleAdd.currentStep;

export const selectSingleAddLastStep = (state: RootState) =>
  state.singleAdd.lastStep;

export const selectAddEmployeeDetails = (state: RootState) =>
  state.singleAdd.employee;

export const selectAddEmployeeErrors = (state: RootState) =>
  state.singleAdd.employeeErrors;

export const selectAddEmployeeIdErrors = (state: RootState) =>
  state.singleAdd.employeeIdErrors;

export const selectAddSelectedPolicyDetails = (state: RootState) =>
  state.singleAdd.selectedPolicyDetails || [];

export const selectAddDependantDetails = (state: RootState) =>
  state.singleAdd.dependants;

export const selectSelectedDependant = (state: RootState) =>
  state.singleAdd.selectedDependant;

export const selectSelectedDependantDetails = (state: RootState) =>
  state.singleAdd.selectedDependant
    ? state.singleAdd.dependants.find(
        (dependant) => dependant._id === state.singleAdd.selectedDependant,
      )
    : null;
export const selectADSelectedEmployee = (state: RootState) =>
  state.singleAdd.selectedEmployee ?? null;

export const selectADPolicyWiseDependents = (state: RootState) =>
  state.singleAdd.selectedEmployee?.policyWiseDeps ?? [];

export const selectADPolicyWiseAllowedDependents = (state: RootState) =>
  selectADPolicyWiseDependents(state).filter((pwd) => pwd.policyType === 'GMC');

export const selectAddDependantErrors = (state: RootState) =>
  state.singleAdd.dependantErrors ?? null;

export const selectDeletingDependantId = (state: RootState) =>
  state.singleAdd.deletingDependantId ?? null;

export const selectFormSubmissionStatus = (state: RootState) =>
  state.singleAdd.formSubmissionStatus;

export const selectActiveDependants = (state: RootState) =>
  state.singleAdd.dependants.filter((dependant) => dependant.firstName);

export const selectMidtermPolicies = (state: RootState) =>
  getMidtermPolicies(
    state.singleAdd.selectedPolicyDetails,
    state.singleAdd.selectedEmployee,
  );

export const selectIsMidtermFlow = (state: RootState) =>
  selectMidtermPolicies(state).length > 0;

export const selectIsExitModalVisible = (state: RootState) =>
  state.singleAdd.exitModalVisible;

export const selectProceedWithoutDeps = (state: RootState) =>
  state.singleAdd.proceedWithoutDeps;

export const {
  updateCurrentStep,
  updateLastStep,
  setAddSelectedPolicies,
  setSelectedDependant,
  setAddDependantDetails,
  setIndividualDependantDetails,
  resetSingleAddState,
  setADSelectedEmployee,
  setAddEmployeeDetails,
  setAddEmployeeErrors,
  setAddEmployeeIdErrors,
  setAddDependantErrors,
  setDeletingDependantId,
  setFormSubmissionStatus,
  setADPolicyWiseDependents,
  setExitModalVisible,
  resetSingleAddDependantsState,
  setProceedWithoutDeps,
} = singleAddSlice.actions;

export default singleAddSlice.reducer;
