export enum PARENTS_TYPE {
  PARENTS_ONLY = 'parentsOnly',
  PARENTS_AND_PARENTS_IN_LAW = 'parentsAndParentsInLaw',
  PARENTS_OR_PARENTS_IN_LAW = 'parentsOrParentsInLaw',
  PARENTS_INCLUDING_PARENTS_IN_LAW = 'parentsIncludingParentsInLaw',
}

export enum PARENTS_RADIO_BUTTONS {
  INSURE_PARENTS = 'insureParents',
  INSURE_PARENTS_IN_LAW = 'insureParentsInLaw',
}
