import styled from 'styled-components';

export const StyledSelectedDepedentsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledSelectedDependentsHeaderText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 6;
`;

export const StyledSelectedDependentsHeaderIcon = styled.img`
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
`;

export const StyledSelectedDependentsHeaderNote = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  padding: 6px 8px;
  background-color: ${(p) => p.theme.colors.lightSilver};
`;

export const StyledSelectedDependentsHeaderNoteIcon = styled.img`
  width: 18px;
  height: 18px;
  aspect-ratio: 1;
`;
