import {
  Avatar,
  DateFormatUtils,
  Typography,
} from '@loophealth/loop-ui-web-library';
import {
  StyledActionButtonImage,
  StyledSectionCard,
  StyledSectionCardFooter,
  StyledSectionCardFooterItem,
  StyledSectionCardHeader,
  StyledSectionCardHeaderActionButtons,
  StyledSectionCardHeaderProfile,
  StyledSectionCardHeaderProfileDetails,
} from './styles';
import {
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  LockOutlinedIcon,
} from '../../../../assets/images';
import { IFilledDependantCardProps } from './types';
import {
  capitalizeFirstLetter,
  textPlaceholder,
} from '../../../../utils/common/Utilities';
import moment from 'moment';
import { formatMemberName } from '../../../../utils/common/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFormSubmissionStatus,
  setDeletingDependantId,
  setSelectedDependant,
} from '../../../../redux/slices/singleAddSlice';
import { ADD_FORM_SUBMISSION_STATUS } from '../../../../redux/slices/singleAddSlice/types';

const FilledDependantCard: React.FC<IFilledDependantCardProps> = ({
  dependant,
  disabled,
}) => {
  const { firstName, lastName, relationship, dob, gender, mobile, _id } =
    dependant;
  const dispatch = useDispatch();
  const formSubmissionStatus = useSelector(selectFormSubmissionStatus);
  const isFormSubmitting =
    formSubmissionStatus !== ADD_FORM_SUBMISSION_STATUS.IDLE;
  const openDependantModal = (relationship: string) => {
    if (isFormSubmitting) {
      return;
    }
    dispatch(setSelectedDependant(relationship));
  };
  const deleteDependant = () => {
    if (isFormSubmitting) {
      return;
    }
    dispatch(setDeletingDependantId(_id || null));
  };
  const name = formatMemberName(firstName, lastName);

  return (
    <StyledSectionCard $disabled={disabled}>
      <StyledSectionCardHeader>
        <StyledSectionCardHeaderProfile>
          <Avatar
            users={[
              {
                name,
                imageURL: '',
              },
            ]}
            size="large"
            shape="square"
          />
          <StyledSectionCardHeaderProfileDetails>
            <Typography variant="extraSmall" color="secondary">
              Name
            </Typography>
            <Typography variant="small" weight="semiBold">
              {name}
            </Typography>
          </StyledSectionCardHeaderProfileDetails>
        </StyledSectionCardHeaderProfile>
        <StyledSectionCardHeaderActionButtons>
          {disabled ? (
            <StyledActionButtonImage src={LockOutlinedIcon} alt="Lock" />
          ) : (
            <>
              {relationship !== 'self' && (
                <StyledActionButtonImage
                  src={DeleteOutlinedIcon}
                  onClick={deleteDependant}
                  alt="Delete"
                />
              )}
              <StyledActionButtonImage
                src={EditOutlinedIcon}
                onClick={() => openDependantModal(_id || '')}
                alt="Edit"
              />
            </>
          )}
        </StyledSectionCardHeaderActionButtons>
      </StyledSectionCardHeader>
      <StyledSectionCardFooter>
        <StyledSectionCardFooterItem>
          <Typography variant="extraSmall" color="secondary">
            Relationship
          </Typography>
          <Typography variant="small" weight="semiBold">
            {capitalizeFirstLetter(relationship)}
          </Typography>
        </StyledSectionCardFooterItem>
        <StyledSectionCardFooterItem>
          <Typography variant="extraSmall" color="secondary">
            DOB
          </Typography>
          <Typography variant="small" weight="semiBold">
            {moment(dob).format(DateFormatUtils.DEFAULT_UI_DATE_FORMAT)}
          </Typography>
        </StyledSectionCardFooterItem>
        <StyledSectionCardFooterItem>
          <Typography variant="extraSmall" color="secondary">
            Gender
          </Typography>
          <Typography variant="small" weight="semiBold">
            {capitalizeFirstLetter(gender)}
          </Typography>
        </StyledSectionCardFooterItem>
        <StyledSectionCardFooterItem>
          <Typography variant="extraSmall" color="secondary">
            Mobile
          </Typography>
          <Typography variant="small" weight="semiBold">
            {textPlaceholder(mobile)}
          </Typography>
        </StyledSectionCardFooterItem>
      </StyledSectionCardFooter>
    </StyledSectionCard>
  );
};

export default FilledDependantCard;
