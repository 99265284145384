import moment from 'moment';
import { IPolicyListItemType } from '../../../../redux/slices/hrdRevampRedux/types';
import {
  IDependant,
  IGender,
  ISelectedEmployee,
  ISingleAddSelectedPolicy,
} from '../../../../redux/slices/singleAddSlice/types';
import {
  EmployeeDetails,
  FamilyMember,
} from '../../../pages/EmployeeDetails/types';
import { IRelationship } from '../../Bulk/types';
import { ISearchResultResponseUserType } from '../../TopNavigation/types';
import { formatMemberName } from '../../../../utils/common/common';

export const checkIfSelfOnlyPolicy = (policyWiseDeps?: EmployeeDetails[]) => {
  return !!policyWiseDeps?.every(
    (policyWiseDep) => policyWiseDep.familyDefinition === 'Self',
  );
};

export const getPreferredEmployeeDependents = (
  policyWiseDeps?: EmployeeDetails[],
) => {
  return (
    policyWiseDeps?.reduce(
      (prev, current) =>
        prev.length > current.family.length ? prev : current.family,
      [] as FamilyMember[],
    ) ?? []
  );
};

export const generateSelectedPolicyFromSingleEnrolled = (
  policyWiseDep: EmployeeDetails,
  companyPolicies: IPolicyListItemType[],
): Record<string, ISingleAddSelectedPolicy> => {
  const enrolledPolicy = companyPolicies.find(
    (policy) => policy.id === policyWiseDep.policyId,
  );
  const slab = enrolledPolicy?.sumAssuredSlabs?.find(
    (slab) => slab.slabId === policyWiseDep.slabId,
  );
  return {
    [policyWiseDep.policyId]: {
      policyId: policyWiseDep.policyId,
      slabId: policyWiseDep.slabId,
      policyType: policyWiseDep.policyType,
      isParentalPolicy: !!enrolledPolicy?.isParentalPolicy,
      isOPDPolicy: !!enrolledPolicy?.isOPDPolicy,
      sumInsured: policyWiseDep.sumInsured,
      sumInsuredStructure: enrolledPolicy?.sumInsuredStructure ?? '',
      slabFamilyDefinition: policyWiseDep.familyDefinition,
      grade: slab?.grade,
    },
  };
};

export const generateSelectedDepsFromSelectedPolicies = (
  policyWiseDeps: EmployeeDetails[],
  selectedEmployee: ISelectedEmployee | null,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
): IDependant[] => {
  const members = {} as Record<string, IDependant>;
  policyWiseDeps.forEach((policyWiseDep) => {
    if (selectedPolicies[policyWiseDep.policyId])
      policyWiseDep.family.forEach((m) => {
        if (!members[m.userId] || policyWiseDep.policyType === 'GMC')
          members[m.userId] = {
            userId: m.userId,
            employeeId: m.employeeId,
            policyStartDate: moment
              .unix(policyWiseDep.policyStartDate._seconds)
              .toDate(),
            relationship: m.relationship as IRelationship,
            dob: moment.unix(m.dob._seconds).toDate(),
            gender: m.gender as IGender,
            email: m.email ?? '',
            mobile: m.mobile ?? '',
            firstName: m.firstName,
            lastName: m.lastName,
            dateOfMarriage: null,
            kycDocumentURLs: '',
            ctc: selectedEmployee?.ctc ?? undefined,
            uhid: m.uhid ?? undefined,
            insuranceStatus: m.insuranceStatus,
            isAlreadyEnrolled: true,
          };
      });
  });
  return Object.values(members);
};

export const convertSearchResultToSelectedEmployee = (
  searchResult: ISearchResultResponseUserType,
): ISelectedEmployee => {
  return {
    ...searchResult,
    name: formatMemberName(searchResult.firstName, searchResult.lastName),
    userPolicies: searchResult.userPolicies || [],
    policyWiseDeps: [],
    _id: 'self',
    policyStartDate: new Date(),
    dob: moment(searchResult.dob).toDate(),
    mobile: searchResult.mobile ?? '',
    email: searchResult.email ?? '',
    relationship: searchResult.relationship as IRelationship,
    ctc: searchResult.ctc ?? '',
    isAlreadyEnrolled: true,
  };
};
