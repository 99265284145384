import styled from 'styled-components';

export const StyledReviewCostScreen = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
`;

export const StyledSingleAddStepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  border-bottom: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
  padding: 16px 0px;
  margin: 0px 56px;
  background-color: ${(p) => p.theme.colors.white};

  & > div {
    width: 60%;
  }
`;

export const StyledSubmitEndoWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.surface0};
  padding-top: 24px;
`;
