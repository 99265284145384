import styled from 'styled-components';

export const StyledSingleAddFaqWrapper = styled.div`
  position: relative;
`;

export const StyledSingleAddFaq = styled.div`
  position: absolute;
  bottom: 56px;
  left: 0;
  background-color: ${(p) => p.theme.colors.white};
  width: 400px;
  border-radius: 12px;
  border: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledSingleAddFaqContentWrapper = styled.div`
  padding: 24px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledSingleAddFaqContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledSingleAddFaqHeader = styled.div`
  display: flex;
  gap: 24px;
  align-items: start;
`;

export const StyledSingleAddFaqHeaderText = styled.div`
  flex: 1;
`;

export const StyledSingleAddFaqList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledSingleAddFaqListItem = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledSingleAddFaqSeperator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledSingleAddFaqNote = styled.div`
  display: flex;
  padding: 8px;
  gap: 12px;
  border-radius: 6px;
  background-color: ${(p) => p.theme.colors.lightSilver};
  align-items: center;
`;

export const StyledSingleAddFaqIconWrapper = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledSingleAddFaqIcon = styled.img`
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
`;

export const StyledSingleAddFaqImportantBadge = styled.div`
  position: absolute;
  width: 91px;
  height: 32px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(p) => p.theme.colors.planRed};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSingleAddStatusIcon = styled.img`
  width: 20px;
  height: 20px;
  aspect-ratio: 1;
`;
