import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../../assets/images';
import {
  resetSingleAddState,
  selectADSelectedEmployee,
  selectIsExitModalVisible,
  selectSingleAddCurrentStep,
  selectSingleAddLastStep,
  setExitModalVisible,
  updateCurrentStep,
} from '../../../../redux/slices/singleAddSlice';
import EndoSingleExitModal from '../../../containers/Bulk/EndoAddExitModal';
import { SINGLE_ADD_DEPENDENT_STEPS } from '../../../pages/Endorsements/SingleAddDependents/constants';
import {
  StyledSingleAddHeader,
  StyledSingleAddHeaderBackButton,
  StyledSingleAddHeaderTitle,
} from '../SingleAddMembersHeader/styles';

const SingleAddDependentsHeader: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentStep = useSelector(selectSingleAddCurrentStep);
  const employeeDetails = useSelector(selectADSelectedEmployee);
  const lastStep = useSelector(
    selectSingleAddLastStep,
  ) as SINGLE_ADD_DEPENDENT_STEPS;
  const exitPopupState = useSelector(selectIsExitModalVisible);

  const isExitPopupVisible =
    exitPopupState &&
    currentStep !== SINGLE_ADD_DEPENDENT_STEPS.SELECT_EMPLOYEE;

  const setCurrentStep = (step: SINGLE_ADD_DEPENDENT_STEPS) => {
    dispatch(updateCurrentStep(step));
  };

  const handlePreviousStep = () => {
    switch (currentStep) {
      case SINGLE_ADD_DEPENDENT_STEPS.CHOOSE_POLICY:
        return setCurrentStep(SINGLE_ADD_DEPENDENT_STEPS.SELECT_EMPLOYEE);
      case SINGLE_ADD_DEPENDENT_STEPS.ADD_DEPENDANTS:
        return setCurrentStep(lastStep);
      case SINGLE_ADD_DEPENDENT_STEPS.REVIEW_COST:
        return setCurrentStep(SINGLE_ADD_DEPENDENT_STEPS.ADD_DEPENDANTS);
    }
  };

  const handleExit = () => {
    dispatch(setExitModalVisible(true));
  };

  const onExitPopupClose = () => {
    dispatch(setExitModalVisible(false));
  };

  const onExit = () => {
    history.goBack();
    dispatch(resetSingleAddState());
  };

  const screenTitle = useMemo(() => {
    switch (currentStep) {
      case SINGLE_ADD_DEPENDENT_STEPS.CHOOSE_POLICY:
        return 'Choose policies for the dependants';
      case SINGLE_ADD_DEPENDENT_STEPS.ADD_DEPENDANTS:
        return `Add Dependants for ${employeeDetails?.name}`;
    }
  }, [currentStep, employeeDetails]);

  return (
    <>
      <StyledSingleAddHeader>
        <StyledSingleAddHeaderTitle>
          <StyledSingleAddHeaderBackButton
            onClick={handlePreviousStep}
            src={ArrowLeftIcon}
            alt="Back Button"
          />
          <Typography variant="medium" weight="medium" color="primary">
            {screenTitle}
          </Typography>
        </StyledSingleAddHeaderTitle>
        <div>
          <LoopButton variant="outline" onClick={handleExit}>
            Exit
          </LoopButton>
        </div>
      </StyledSingleAddHeader>
      <EndoSingleExitModal
        isVisible={isExitPopupVisible}
        onClose={onExitPopupClose}
        onExit={onExit}
      ></EndoSingleExitModal>
    </>
  );
};
export default SingleAddDependentsHeader;
