import moment from 'moment';
import { IPolicyListItemType } from '../../../../redux/slices/hrdRevampRedux/types';
import {
  IDependant,
  ISelectedEmployee,
  ISingleAddSelectedPolicy,
} from '../../../../redux/slices/singleAddSlice/types';
import { IButtonState, IRelationship } from '../../../containers/Bulk/types';
import { EmployeeDetails, FamilyMember } from '../../EmployeeDetails/types';
import { InsuranceStatusValues } from '../../EmployeeDetails/utils';
import { IBulkAddData } from '../BulkAddMembers/types';
import { SINGLE_ADD_DEPENDENT_STEPS } from './constants';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { formatMemberName } from '../../../../utils/common/common';
import { findLatestDateFrom } from '../../../../utils/common/Utilities';

export const generateADPolicyList = (
  companyPolicies: IPolicyListItemType[],
  policyWiseDeps: EmployeeDetails[],
) => {
  const companyParentalPolicies = companyPolicies.filter(
    (policy) => policy.isParentalPolicy,
  );
  const userHasParental = policyWiseDeps.some((policyWiseDeps) =>
    companyParentalPolicies.find(
      (policy) => policy.id === policyWiseDeps.policyId,
    ),
  );
  const policies: IPolicyListItemType[] = [];
  companyPolicies.forEach((policy) => {
    const alreadyEnrolled = policyWiseDeps.find(
      (pwd) => pwd.policyId === policy.id,
    );
    if (!policy.isTopUpPolicy && alreadyEnrolled)
      policies.push({
        ...policy,
        sumAssuredSlabs: policy.sumAssuredSlabs?.filter(
          (slab) => alreadyEnrolled.slabId === slab.slabId,
        ),
      });
    else if (!userHasParental && policy.isParentalPolicy) policies.push(policy);
  });
  return policies;
};

export const getButtonStates = (
  currentStep: SINGLE_ADD_DEPENDENT_STEPS,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  totalLives: number,
  addedDependants: IDependant[],
): IButtonState => {
  switch (currentStep) {
    case SINGLE_ADD_DEPENDENT_STEPS.CHOOSE_POLICY:
      return {
        isNextEnabled: Object.keys(selectedPolicies).length > 0,
        isBackEnabled: false,
        buttonText: 'Proceed to Add Details',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
    case SINGLE_ADD_DEPENDENT_STEPS.ADD_DEPENDANTS:
      return {
        isNextEnabled: addedDependants.length > 0,
        isBackEnabled: false,
        buttonText: 'Review Addition Cost',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
    case SINGLE_ADD_DEPENDENT_STEPS.REVIEW_COST:
      return {
        isNextEnabled: true,
        isBackEnabled: true,
        buttonText: `Submit ${totalLives} Lives`,
        backButtonText: 'Go Back',
        backButtonVariant: 'outline',
      };
    default:
      return {
        isNextEnabled: false,
        isBackEnabled: false,
        buttonText: '',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
  }
};

export const getMidtermPolicies = (
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  employee: ISelectedEmployee | null,
): string[] => {
  if (!employee) return [];
  return Object.keys(selectedPolicies).filter((policyId) => {
    const policyWiseDeps = employee.policyWiseDeps?.find(
      (policyWiseDep) => policyWiseDep.policyId === policyId,
    );
    const selfAdded = policyWiseDeps?.family.find(
      (m) => m.relationship === 'self',
    );
    return selfAdded?.insuranceStatus === InsuranceStatusValues.INSURED;
  });
};

export const transformDependentIntoBulkMidtermEntity = (
  dependent: IDependant,
  employee: FamilyMember,
  selectedEmployee: ISelectedEmployee,
  policy: ISingleAddSelectedPolicy,
  isMidterm: boolean,
  coverageStartDate: Date,
): IBulkAddData => {
  const policyStartDate = isMidterm
    ? dependent.relationship === 'spouse'
      ? findLatestDateFrom(dependent.dateOfMarriage!, coverageStartDate)
      : findLatestDateFrom(dependent.dob!, coverageStartDate)
    : coverageStartDate;
  return {
    sumInsured: policy.sumInsured,
    grade: policy.grade ?? '',
    slabId: Number(policy.slabId),
    employee_id: employee.employeeId || '',
    policy_start_date: moment(policyStartDate).format(
      DateFormatUtils.DEFAULT_BACKEND_DATE_FORMAT,
    ),
    ctc: `${selectedEmployee.ctc}`,

    date_of_birth: moment(dependent.dob).format(
      DateFormatUtils.DEFAULT_BACKEND_DATE_FORMAT,
    ),
    relationship_to_account_holders: dependent.relationship as IRelationship,
    name: formatMemberName(dependent.firstName, dependent.lastName),
    gender: dependent.gender,
    email_address: dependent.email,
    mobile: dependent.mobile,
    isMidtermMember: isMidterm,
    kycDocumentURLs: dependent.kycDocumentURLs
      ? [dependent.kycDocumentURLs]
      : [],
  };
};
