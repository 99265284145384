import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../../assets/images';
import {
  resetSingleAddState,
  selectAddEmployeeDetails,
  selectIsExitModalVisible,
  selectSingleAddCurrentStep,
  selectSingleAddLastStep,
  setExitModalVisible,
  updateCurrentStep,
} from '../../../../redux/slices/singleAddSlice';
import EndoSingleExitModal from '../../../containers/Bulk/EndoAddExitModal';
import { SINGLE_ADD_STEPS } from '../../../pages/Endorsements/SingleAddMembers/constants';
import {
  StyledSingleAddHeader,
  StyledSingleAddHeaderBackButton,
  StyledSingleAddHeaderTitle,
} from './styles';
import { formatMemberName } from '../../../../utils/common/common';

const SingleAddMembersHeader: React.FC = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectSingleAddCurrentStep);
  const lastStep = useSelector(selectSingleAddLastStep) as SINGLE_ADD_STEPS;
  const employeeDetails = useSelector(selectAddEmployeeDetails);
  const exitPopupVisible = useSelector(selectIsExitModalVisible);
  const history = useHistory();
  const setCurrentStep = (step: SINGLE_ADD_STEPS) => {
    dispatch(updateCurrentStep(step));
  };

  const handlePreviousStep = () => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        setCurrentStep(SINGLE_ADD_STEPS.CHOOSE_POLICY);
        break;
      case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
        setCurrentStep(SINGLE_ADD_STEPS.ADD_EMPLOYEE);
        break;
      case SINGLE_ADD_STEPS.CHOOSE_POLICY:
        handleExit();
        break;
      case SINGLE_ADD_STEPS.REVIEW_COST:
        setCurrentStep(lastStep);
        break;
    }
  };

  const handleExit = () => {
    dispatch(setExitModalVisible(true));
  };

  const onExitPopupClose = () => {
    dispatch(setExitModalVisible(false));
  };

  const onSingleAddExit = () => {
    history.goBack();
    dispatch(resetSingleAddState());
  };

  const screenTitle = useMemo(() => {
    switch (currentStep) {
      case SINGLE_ADD_STEPS.CHOOSE_POLICY:
        return 'Choose policies for the employee';
      case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
        return 'Add Employee Details';
      case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
        return `Add Dependants for ${formatMemberName(
          employeeDetails.firstName,
          employeeDetails.lastName,
        )}`;
    }
  }, [currentStep, employeeDetails]);

  return (
    <>
      <StyledSingleAddHeader>
        <StyledSingleAddHeaderTitle>
          <StyledSingleAddHeaderBackButton
            onClick={handlePreviousStep}
            src={ArrowLeftIcon}
            alt="Back Button"
          />
          <Typography variant="medium" weight="medium" color="primary">
            {screenTitle}
          </Typography>
        </StyledSingleAddHeaderTitle>
        <div>
          <LoopButton variant="outline" onClick={handleExit}>
            Exit
          </LoopButton>
        </div>
      </StyledSingleAddHeader>
      <EndoSingleExitModal
        isVisible={exitPopupVisible}
        onClose={onExitPopupClose}
        onExit={onSingleAddExit}
      ></EndoSingleExitModal>
    </>
  );
};
export default SingleAddMembersHeader;
