import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import moment from 'moment';
import LoopApiService from '../../../../adaptars/LoopApiService';
import {
  IDependant,
  IEmployeeErrors,
  IEmployeeFormData,
  IEmployeeIdErrors,
  ISelectedEmployee,
  ISingleAddSelectedPolicy,
} from '../../../../redux/slices/singleAddSlice/types';
import { formatMemberName } from '../../../../utils/common/common';
import {
  findLatestDateFrom,
  parseResponse,
} from '../../../../utils/common/Utilities';
import {
  IBulkAddData,
  IBulkPayload,
  PolicyWiseValidationResponse,
} from '../BulkAddMembers/types';
import { IButtonState, IRelationship } from './../../../containers/Bulk/types';
import {
  allEmployeeDetailFields,
  mandatoryEmployeeDetailFields,
  SINGLE_ADD_STEPS,
} from './constants';
import { familyDefinition } from '../../../containers/EndoSingleAdd/mapping';

export const validateEmployeeDetails = async (
  selfDetails: IEmployeeFormData,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  companyId: string,
) => {
  const policies = Object.values(selectedPolicies);
  const payload: IBulkPayload = {
    companyId,
    policies: policies.map((policy) => ({
      policyId: policy.policyId,
      membersList: [
        transformDependentIntoBulkEntity(selfDetails, selfDetails, policy),
      ],
    })),
  };
  const [error, response] = await parseResponse(
    LoopApiService.validateBulkAdditionData(payload),
  );
  if (error) throw error;
  let errors: Record<string, string> = {};
  const employeeAlreadyRegisteredPolicies: string[] = [];
  response.data?.invalidMembersPerPolicy.forEach(
    (policy: PolicyWiseValidationResponse) => {
      if (policy.invalidMembers.length) {
        try {
          const memberErrors = JSON.parse(policy.invalidMembers[0].errors);
          errors = { ...errors, ...memberErrors };
          if (memberErrors.employeeId || memberErrors.employee_id) {
            employeeAlreadyRegisteredPolicies.push(policy.policyId);
          }
        } catch (e) {}
      }
    },
  );
  return {
    errors,
    employeeAlreadyRegisteredPolicies,
    employeeAlreadyPresent:
      employeeAlreadyRegisteredPolicies.length === policies.length,
    employeePartiallyPresent:
      employeeAlreadyRegisteredPolicies.length !== policies.length &&
      employeeAlreadyRegisteredPolicies.length > 0,
    erroredEmployeeId: selfDetails.employeeId,
    name: formatMemberName(selfDetails.firstName, selfDetails.lastName),
  };
};

export const validateDependantDetails = async (
  companyId: string,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  selfDetails: IEmployeeFormData,
  dependantsDetails: IDependant[],
  isOldEmployeeFlow: boolean,
) => {
  const policies = Object.values(selectedPolicies);
  let validDependants = dependantsDetails.filter(
    (dependant) => dependant.firstName,
  );

  const payload: IBulkPayload = {
    companyId,
    policies: policies.map((policy) => {
      const eligibleDependants = getPolicyBasedDependants(
        policy,
        validDependants,
        isOldEmployeeFlow,
      ).map(({ _id, ...dependant }) => dependant);
      return {
        policyId: policy.policyId,
        membersList: eligibleDependants.map((dependant) =>
          transformDependentIntoBulkEntity(dependant, selfDetails, policy),
        ),
      };
    }),
  };
  const [error, response] = await parseResponse(
    LoopApiService.validateBulkAdditionData(payload),
  );
  if (error) throw error;
  let errors: Record<string, string> = {};
  response.data?.invalidMembersPerPolicy.forEach(
    (policy: PolicyWiseValidationResponse) => {
      if (policy.invalidMembers.length) {
        try {
          const memberErrors = JSON.parse(policy.invalidMembers[0].errors);
          errors = { ...errors, ...memberErrors };
        } catch (e) {}
      }
    },
  );
  return {
    errors,
    erroredEmployeeId: selfDetails.employeeId,
  };
};

export const getEmpIdErrorAlertText = (
  empErrors: IEmployeeIdErrors | null,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
) => {
  if (empErrors?.employeeAlreadyPresent)
    return `Employee "${empErrors.name}" with Employee ID "${empErrors.erroredEmployeeId}" already exists in our records. Please recheck the employee ID to proceed.`;
  if (empErrors?.employeePartiallyPresent) {
    const policyNames = empErrors.employeeAlreadyRegisteredPolicies
      ?.map((policyId) => selectedPolicies[policyId].policyType)
      .join(', ')
      .replace(/,([^,]*)$/, ' and$1');
    return `Employee "${empErrors.name}" with Employee ID "${empErrors.erroredEmployeeId}" is already insured under the ${policyNames} policies. Check the Employee ID or deselect the policies.`;
  }
  return undefined;
};

export const checkIfCtcRequired = (
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
) => {
  return Object.values(selectedPolicies).some(
    (policy) => policy.sumInsuredStructure === 'CTC Multiplier',
  );
};

export const checkNextEnabledForEmployee = (
  employeeDetails: IEmployeeFormData,
  employeeErrors: IEmployeeErrors,
  isCtcRequired: boolean,
) => {
  const hasErrors = allEmployeeDetailFields.some((field) =>
    Boolean(employeeErrors[field]),
  );
  return (
    !hasErrors &&
    mandatoryEmployeeDetailFields.every((field) =>
      Boolean(employeeDetails[field]),
    ) &&
    (!isCtcRequired || !!employeeDetails.ctc)
  );
};

export const checkIfParentalOnlyPolicy = (
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
) => {
  const policies = Object.values(selectedPolicies);
  return policies.length === 1 && policies[0].isParentalPolicy;
};

export const getButtonStates = (
  currentStep: SINGLE_ADD_STEPS,
  selectedPolicies: Record<string, ISingleAddSelectedPolicy>,
  employeeDetails: IEmployeeFormData,
  employeeErrors: IEmployeeErrors,
  hasDependentsEnabled: boolean,
  totalLives: number,
  dependentsList: IDependant[],
): IButtonState => {
  const isParentalOnly = checkIfParentalOnlyPolicy(selectedPolicies);
  switch (currentStep) {
    case SINGLE_ADD_STEPS.CHOOSE_POLICY:
      return {
        isNextEnabled: Object.keys(selectedPolicies).length > 0,
        isBackEnabled: false,
        buttonText: 'Proceed to Add Details',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
    case SINGLE_ADD_STEPS.ADD_EMPLOYEE:
      const isNextEnabled = checkNextEnabledForEmployee(
        employeeDetails,
        employeeErrors,
        checkIfCtcRequired(selectedPolicies),
      );
      return {
        isNextEnabled,
        isBackEnabled: hasDependentsEnabled && !isParentalOnly,
        buttonText: hasDependentsEnabled
          ? 'Proceed to Add Dependants'
          : 'Review Addition Cost',
        backButtonText: 'Proceed Without Dependants',
        backButtonVariant: 'secondary',
      };
    case SINGLE_ADD_STEPS.ADD_DEPENDANTS:
      return {
        isNextEnabled: !isParentalOnly || dependentsList.length > 1,
        isBackEnabled: false,
        buttonText: 'Review Addition Cost',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
    case SINGLE_ADD_STEPS.REVIEW_COST:
      return {
        isNextEnabled: true,
        isBackEnabled: true,
        buttonText: `Submit ${totalLives} Lives`,
        backButtonText: 'Go Back',
        backButtonVariant: 'outline',
      };
    default:
      return {
        isNextEnabled: false,
        isBackEnabled: false,
        buttonText: '',
        backButtonText: '',
        backButtonVariant: 'outline',
      };
  }
};

export const transformDependentIntoBulkEntity = (
  dependent: IDependant | IEmployeeFormData,
  employee: IEmployeeFormData,
  policy: ISingleAddSelectedPolicy,
): IBulkAddData => {
  const policyStartDate = dependent.policyStartDate
    ? findLatestDateFrom(dependent.policyStartDate, employee.policyStartDate!)
    : employee.policyStartDate;
  return {
    sumInsured: policy.sumInsured,
    grade: policy.grade ?? '',
    slabId: Number(policy.slabId),
    employee_id: employee.employeeId,
    policy_start_date: moment(policyStartDate).format(
      DateFormatUtils.DEFAULT_BACKEND_DATE_FORMAT,
    ),
    ctc: `${employee.ctc}`,

    date_of_birth: moment(dependent.dob).format(
      DateFormatUtils.DEFAULT_BACKEND_DATE_FORMAT,
    ),
    relationship_to_account_holders: dependent.relationship as IRelationship,
    name: formatMemberName(dependent.firstName, dependent.lastName),
    gender: dependent.gender,
    email_address: dependent.email,
    mobile: dependent.mobile,
  };
};

export const getPolicyBasedDependants = (
  policy: ISingleAddSelectedPolicy,
  dependants: IDependant[],
  isOldEmployeeFlow: boolean,
): IDependant[] => {
  const slabFamilyDefinition = policy.slabFamilyDefinition;
  const familyStructure = familyDefinition[slabFamilyDefinition];
  const isParentalPolicy = policy.isParentalPolicy;
  const employee = dependants.find(
    (dependant) => dependant.relationship === 'self',
  );
  const eligibleDependants = [employee];
  familyStructure.dependents?.forEach((dependant) => {
    if (dependant.relation === 'spouse') {
      const spouse = dependants.find((dep) => dep.relationship === 'spouse');
      if (spouse) eligibleDependants.push(spouse);
    } else if (dependant.relation === 'child') {
      const children = dependants.filter((dep) => dep.relationship === 'child');
      eligibleDependants.push(...children);
    } else if (
      ['parent/parent-in-law', 'parent|parent-in-law'].includes(
        dependant.relation,
      )
    ) {
      const parents = dependants.filter((dep) =>
        ['parent', 'parent-in-law'].includes(dep.relationship || ''),
      );
      eligibleDependants.push(...parents);
    } else if (dependant.relation === 'parent') {
      const parents = dependants.filter((dep) => dep.relationship === 'parent');
      eligibleDependants.push(...parents);
    } else if (dependant.relation === 'parent-in-law') {
      const parentsInLaw = dependants.filter(
        (dep) => dep.relationship === 'parent-in-law',
      );
      eligibleDependants.push(...parentsInLaw);
    }
  });
  // eligibleDependants groupby _id
  const uniqueEligibleDependants = eligibleDependants.reduce<
    Record<string, IDependant>
  >((acc, curr) => {
    if (curr && curr._id) {
      if (!acc[curr._id]) {
        acc[curr._id] = curr;
      }
    }
    return acc;
  }, {});
  return Object.values(uniqueEligibleDependants).filter((dependant) =>
    isOldEmployeeFlow
      ? isParentalPolicy && dependant.relationship
        ? !['spouse', 'child'].includes(dependant.relationship)
        : dependant.relationship !== 'self'
      : true,
  );
};
