import { customAlphabet } from 'nanoid';
import moment from 'moment';
import { toast } from 'react-toastify';
import LoopApiService from '../../adaptars/LoopApiService';
import { EXTENTION_TO_MIME_TYPE } from './mapping';
import { IFirebaseTimeStamp } from '../../components/containers/TopNavigation/types';

export const validateMobile = (value: string) => {
  if (!value || value.length !== 10) {
    return null;
  }
  var regex = /^[0-9]+$/;
  return regex.test(value);
};

export const validateEmail = (emailTxt: string) => {
  if (!emailTxt) {
    return null;
  }
  // var regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var regEx = /^[^ ]+@[^ ]+\.[a-z]{2,4}$/;
  return regEx.test(emailTxt);
};

export const getLoopId = (prefix = 'LPU') => {
  let nanoid_6 = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);
  let documentId = `${prefix}-` + nanoid_6().toLocaleUpperCase();
  return documentId;
};

function ExcelDateToJSDate(serial: number) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds,
  );
}

const convertToISOFormat = (date: Date) => {
  let nonISODateObject = new Date(date.toISOString());
  let isoDateObject =
    nonISODateObject.getFullYear() +
    '-' +
    (nonISODateObject.getMonth() + 1) +
    '-' +
    nonISODateObject.getDate();
  return isoDateObject;
};

//converts DD/MM/YYYY into timestamp
export const convertToTimestamp = (date: string) => {
  if (!date) {
    return null;
  }
  const [year, month, day] = date.split('-');
  const dobTimeStamp = new Date(Number(year), Number(month) - 1, Number(day));
  if (!month) {
    return ExcelDateToJSDate(parseInt(date, 10));
  }
  return convertToISOFormat(dobTimeStamp);
};

//converts timestamp into DD/MM/YYYY
export const convertToDate = (
  data: IFirebaseTimeStamp | undefined | null,
  dateFormat = 'DD/MM/YYYY',
) => {
  if (!data) {
    return null;
  }
  const dateData = data.seconds || data._seconds;
  if (dateData) {
    return moment.unix(dateData).format(dateFormat);
  }
  return null;
};

export const getAgeFromTimestamp = (timestamp: IFirebaseTimeStamp) => {
  const date = convertToDate(timestamp);
  if (!date) return null;
  return moment().diff(moment(date, 'DD/MM/YYYY'), 'years');
};

export const convertDateToFirebaseTimestamp = (date: string) => ({
  _seconds: new Date(date).getTime() / 1000,
  _nanoseconds: 0,
});

export const removeEmpty = (obj: Record<string, unknown>) => {
  let newObj: Record<string, unknown> = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key]))
      newObj[key] = removeEmpty(obj[key] as Record<string, unknown>);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export const getAmountInLacs = (
  amount: number,
  lacTextSingular = 'L',
  lacTextPlural = 'L',
) =>
  `₹${
    amount >= 100000
      ? `${amount / 100000}${
          amount / 100000 === 1 ? lacTextSingular : lacTextPlural
        }`
      : amount
  }`;

export const getDisplayTextFromUpper = (uppercaseText = '') =>
  capitalizeFirstLetter(uppercaseText.replaceAll('_', ' ').toLowerCase());
export const capitalizeFirstLetter = (word = '') =>
  (word.charAt(0)?.toUpperCase() ?? '') + (word.slice(1) ?? '');
export const capitalizeFirstLetterOfEachWord = (str: string | undefined) =>
  str
    ? str
        .toString()
        .toLowerCase()
        .split(' ')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ')
    : '--';

export const capitalizeFirstLetterOfEachWordWithoutLower = (
  str: string | undefined,
) =>
  str
    ? str
        .toString()
        .split(' ')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ')
    : '--';

export const textPlaceholder = <T = string | number | undefined | null>(
  text: T,
) => (text ? text : '--');

export const getPolicyType = (
  name = '',
  isTopupPolicy: boolean,
  isParentalPolicy: boolean,
  isOpdPolicy: boolean,
) => {
  if (name.toLowerCase() === 'gmc') {
    if (isTopupPolicy && isParentalPolicy) return 'GMC - Parental Topup';
    else if (isTopupPolicy) return 'GMC - Top-Up';
    else if (isParentalPolicy) return 'GMC - Parental';
    else if (isOpdPolicy) return 'GMC - OPD';
    else return 'GMC';
  } else if (name.toLowerCase() === 'gpa') {
    return isTopupPolicy ? 'GPA - Top-Up' : 'GPA';
  } else if (name.toLowerCase() === 'gtl') {
    return isTopupPolicy ? 'GTL - Top-Up' : 'GTL';
  } else if (name.toLowerCase() === 'covid') {
    return isTopupPolicy ? 'Covid - Top-Up' : 'Covid';
  }
  return '';
};

export const downloadFile = (
  fileLink: string | File | Blob,
  fileName: string,
) => {
  const link = document.createElement('a');
  if (fileLink instanceof Blob) {
    const url = URL.createObjectURL(fileLink);
    link.href = url;
  } else link.href = fileLink;
  link.download = fileName;
  if (fileName.toLowerCase().endsWith('.pdf')) link.target = '_blank';
  link.click();
  link.remove();
};

export const parseResponse = async <T = Record<string, unknown>>(
  promise: Promise<T>,
) => {
  return promise
    .then((data) => {
      return [null, data];
    })
    .catch((err) => [err]);
};

export const formatCurrencyAmount = (
  value: number | string | undefined,
  symbol = '₹',
) => {
  value = value || 0;
  value = typeof value === 'string' ? value.replace(/,/g, '') : value;
  const formattedValue = new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value as number);
  return `${symbol}${formattedValue}`;
};

export const formatCurrencyAmountAbbr = (
  value: number | string | undefined,
) => {
  value = value || 0;
  value = typeof value === 'string' ? value.replace(/,/g, '') : value;
  value = Number(value);
  let amt = value;
  const INR_THOUSAND = 1000;
  const INR_LAKH = 100 * INR_THOUSAND;
  const INR_CRORE = 100 * INR_LAKH;
  const formatter = new Intl.NumberFormat('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  switch (true) {
    case isNaN(value):
      return '--';
    case value >= INR_CRORE:
      amt = value / INR_CRORE;
      return `${formatter.format(amt)} ${amt === 1 ? 'crore' : 'crores'}`;
    case value >= INR_LAKH:
      amt = value / INR_LAKH;
      return `${formatter.format(amt)} ${amt === 1 ? 'lakh' : 'lakhs'}`;
    case value >= INR_THOUSAND:
      amt = value / INR_THOUSAND;
      return `${formatter.format(amt)}K`;
    default:
      return formatter.format(value);
  }
};

export const showErrorToast = (message: string) => {
  toast.error(message || 'Something went wrong!', {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const getCurrencyString = (cost: number) =>
  cost.toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'INR',
  });

export const groupArrayOfObjectsByKey = <T>(
  list: T[],
  keyFunc: (item: T) => number | string,
) =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [keyFunc(obj)]: (hash[keyFunc(obj)] || []).concat(obj),
    }),
    {} as Record<number | string, T[]>,
  );

export const convertNumberWithSeprator = (cost: string | number) =>
  cost.toLocaleString('en-IN');

export const convertToNearestThousand = (value: string | number) => {
  if (typeof value === 'string') value = Number(value);
  return Math.round(value / 1000) * 1000;
};
export const toUnderscoreName = (name = '') => {
  return name.replace(/[^a-zA-Z0-9]+/g, '_');
};

export const processSignedOperation = async (
  URL: string,
  method: string,
  body?: BodyInit,
  headers = {},
) => {
  const response = await fetch(URL, {
    method,
    body,
    headers,
  });
  if (!response.ok) {
    throw new Error('Some error occured while processing file');
  }
  return response;
};

export const downloadSignedFile = async (
  URL: string,
  method: string,
  fileName: string,
) => {
  const file = await processSignedOperation(URL, method);
  downloadFile(file.url, fileName);
};

const getMimeTypeByFileName = (name: string) => {
  const fileType = name.split('.').pop();
  if (!fileType) return fileType;
  return EXTENTION_TO_MIME_TYPE[fileType];
};

export const uploadUserFileToBucket = async (
  employeeId: string,
  name: string,
  file: File,
) => {
  const [error, response] = await parseResponse(
    LoopApiService.uploadDocument(employeeId, name, file.name),
  );
  if (error) throw error;
  const data = response.data;
  const headers = {
    'x-goog-content-length-range': '0,26214400',
    'content-type': getMimeTypeByFileName(file.name),
  };
  await processSignedOperation(data.signedURL, data.method, file, headers);
  return data.filePath;
};

export const deleteUserFileFromBucket = async (
  filePath: string | undefined,
) => {
  if (!filePath) return;
  const [error, response] = await parseResponse(
    LoopApiService.deleteDocument(filePath),
  );
  if (error) throw error;
  const data = response.data;
  await processSignedOperation(data.signedURL, data.method);
};

export const downloadUserFileFromBucket = async (
  filePath: string,
  toBlob = false,
) => {
  if (!filePath) return;
  const [error, response] = await parseResponse(
    LoopApiService.downloadDocument(filePath),
  );
  if (error) throw error;
  const data = response.data;
  if (toBlob) {
    return (await processSignedOperation(data.signedURL, data.method)).blob();
  } else await downloadSignedFile(data.signedURL, data.method, filePath);
};

export const findLatestDateFrom = (...dates: Date[]) => {
  return new Date(Math.max(...dates.map((date) => date.getTime())));
};
