import { SINGLE_ADD_STEPS } from '../../../components/pages/Endorsements/SingleAddMembers/constants';
import {
  ADD_FORM_SUBMISSION_STATUS,
  IDependant,
  IEmployeeErrors,
  IEmployeeFormData,
  ISingelAddSliceState,
} from './types';

export const initialEmployeeDetails: IEmployeeFormData = {
  employeeId: '',
  firstName: '',
  lastName: '',
  gender: '',
  dob: null,
  policyStartDate: null,
  email: '',
  mobile: '',
  ctc: '',
  _id: 'self',
  relationship: 'self',
};

export const initialEmployeeErrors: IEmployeeErrors = {
  firstName: '',
  lastName: '',
  gender: '',
  dob: '',
  policyStartDate: '',
  email: '',
  mobile: '',
};

export const initialDependantErrors = {
  employeeId: '',
  dateOfJoining: '',
  relationship: '',
  firstName: '',
  lastName: '',
  dob: '',
  gender: '',
  mobile: '',
  email: '',
  dateOfMarriage: '',
  kycDocumentURLs: '',
  policyStartDate: '',
  ctc: '',
};

export const initialDependantDetails: IDependant = {
  employeeId: '',
  policyStartDate: null,
  firstName: '',
  lastName: '',
  gender: '',
  dob: null,
  dateOfMarriage: null,
  kycDocumentURLs: '',
  email: '',
  mobile: '',
  ctc: '',
  global: '',
};

export const initialState: ISingelAddSliceState = {
  currentStep: SINGLE_ADD_STEPS.CHOOSE_POLICY,
  selectedPolicyDetails: {},
  employee: initialEmployeeDetails,
  dependants: [],
  employeeErrors: initialEmployeeErrors,
  employeeIdErrors: null,
  dependantErrors: initialDependantErrors,
  selectedDependant: null,
  selectedEmployee: null,
  deletingDependantId: null,
  formSubmissionStatus: ADD_FORM_SUBMISSION_STATUS.IDLE,
  lastStep: SINGLE_ADD_STEPS.CHOOSE_POLICY,
  exitModalVisible: false,
  proceedWithoutDeps: false,
};
