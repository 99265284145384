import styled from 'styled-components';
import { IStyledEmptyDependantCard } from './types';

export const StyledEmptyDependantCard = styled.div<IStyledEmptyDependantCard>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.seafoamGray};
  border-radius: 12px;
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.lightSilver : theme.colors.surface0};
  width: 48%;
  min-height: 150px;
  cursor: pointer;
`;
