import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  selectActiveDependants,
  selectAddDependantDetails,
  selectAddEmployeeDetails,
  selectAddSelectedPolicyDetails,
  selectADPolicyWiseDependents,
  selectADSelectedEmployee,
  setAddDependantDetails,
  setAddEmployeeDetails,
} from '../../../../redux/slices/singleAddSlice';
import { familyDefinition } from '../mapping';
import { initialDependantDetails } from '../../../../redux/slices/singleAddSlice/initialState';
import { useMemo } from 'react';
import { PARENTS_TYPE } from './constants';
import {
  IDependant,
  IGender,
} from '../../../../redux/slices/singleAddSlice/types';
import { IDependantUIMapping } from './types';
import { IRelationship } from '../../Bulk/types';
import { generateDependantsData } from './utils';
import { generateSelectedDepsFromSelectedPolicies } from '../EmployeeSelectionModal/utils';

export const useDependantsHelper = () => {
  const dispatch = useDispatch();
  const dependants = useSelector(selectAddDependantDetails, shallowEqual);
  const employeeDetails = useSelector(selectAddEmployeeDetails, shallowEqual);
  const selectedADEmployee = useSelector(selectADSelectedEmployee);
  const alreadyAddedPolicyWiseDeps = useSelector(selectADPolicyWiseDependents);
  const activeDependants = useSelector(selectActiveDependants);
  const selectedPolicyDetails = useSelector(selectAddSelectedPolicyDetails);
  const existingDependants: IDependant[] =
    generateSelectedDepsFromSelectedPolicies(
      alreadyAddedPolicyWiseDeps,
      selectedADEmployee,
      selectedPolicyDetails,
    );
  const gmcfamilyStructure = useMemo(
    () =>
      Object.values(selectedPolicyDetails).find(
        (policy) => policy.policyType === 'GMC' && !policy.isParentalPolicy,
      )?.slabFamilyDefinition,
    [selectedPolicyDetails],
  );
  const parentalFamilyStructure = useMemo(
    () =>
      Object.values(selectedPolicyDetails).find(
        (policy) => policy.policyType === 'GMC' && policy.isParentalPolicy,
      )?.slabFamilyDefinition,
    [selectedPolicyDetails],
  );
  const gmcFamilyDefinition = gmcfamilyStructure
    ? familyDefinition[gmcfamilyStructure]
    : null;

  const parentalFamilyDefinition = parentalFamilyStructure
    ? familyDefinition[parentalFamilyStructure]
    : null;

  const selectedFamilyDefinition = useMemo(() => {
    if (gmcFamilyDefinition) {
      const parentalDependants = parentalFamilyDefinition
        ? parentalFamilyDefinition.dependents
        : [];
      return {
        ...gmcFamilyDefinition,
        dependents: [
          ...(gmcFamilyDefinition.dependents || []),
          ...(parentalDependants || []),
        ],
      };
    } else if (parentalFamilyDefinition) {
      return parentalFamilyDefinition;
    }
    return familyDefinition['Self'];
  }, [gmcFamilyDefinition, parentalFamilyDefinition]);

  const dependantsUIMapping: IDependantUIMapping = useMemo(() => {
    const selfAndSpouse = dependants.filter((dependant) =>
      ['spouse', 'self'].includes(dependant.relationship as IRelationship),
    );

    return {
      selfAndSpouse: {
        sectionTitle: selfAndSpouse.length > 1 ? 'Self + Spouse' : 'Self',
        sectionSubTitle: '',
        dependants: selfAndSpouse,
      },
      children: {
        sectionTitle: 'Children',
        sectionSubTitle:
          '(This policy allows insuring 2 children upto the age of 25 years)',
        dependants: dependants.filter(
          (dependant) => dependant.relationship === 'child',
        ),
      },
      parentsOrParentsInLaw: {
        sectionTitle: 'Parents OR Parents - in - law',
        sectionSubTitle:
          '(This policy allows insuring either your parents or your parents in law)',
        dependants: [],
      },
      parentsIncludingParentsInLaw: {
        sectionTitle: 'Parents (including in-laws)',
        sectionSubTitle:
          '(This policy allows insuring both your parents and your parents in law)',
        dependants: [],
      },
      parents: {
        sectionTitle: 'Parents',
        dependants: dependants.filter(
          (dependant) => dependant.relationship === 'parent',
        ),
      },
      parentsInLaw: {
        sectionTitle: 'Parents-in-law',
        dependants: dependants.filter(
          (dependant) => dependant.relationship === 'parent-in-law',
        ),
      },
    };
  }, [dependants]);

  const parentsType = useMemo(() => {
    if (
      selectedFamilyDefinition.value.includes('Parents (including in-laws)')
    ) {
      return PARENTS_TYPE.PARENTS_INCLUDING_PARENTS_IN_LAW;
    } else if (
      selectedFamilyDefinition.value.includes('Parents OR parents-in-law') ||
      selectedFamilyDefinition.value.includes('Parents OR parent-in-laws')
    ) {
      return PARENTS_TYPE.PARENTS_OR_PARENTS_IN_LAW;
    } else if (
      selectedFamilyDefinition.value.includes('Parents + Parents-in-law')
    ) {
      return PARENTS_TYPE.PARENTS_AND_PARENTS_IN_LAW;
    } else if (
      ['Parents', 'Parents Only'].includes(selectedFamilyDefinition.value) ||
      selectedFamilyDefinition.value.includes('Parents')
    ) {
      return PARENTS_TYPE.PARENTS_ONLY;
    }
    return null;
  }, [selectedFamilyDefinition]);

  const generateInitialDependantsData = () => {
    const employee = {
      ...(selectedADEmployee ?? employeeDetails),
      relationship: 'self' as IRelationship,
      _id: 'self',
      gender: (selectedADEmployee ?? employeeDetails).gender as IGender,
    };
    const dependantsData = generateDependantsData({
      employee,
      existingDependants,
      initialDependantDetails,
      selectedFamilyDefinition,
    });
    dispatch(setAddDependantDetails(dependantsData));
    dispatch(setAddEmployeeDetails(employee));
  };

  return {
    dependants,
    activeDependants,
    selectedPolicyDetails,
    dependantsUIMapping,
    parentsType,
    generateInitialDependantsData,
  };
};
