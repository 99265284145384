import {
  BadSmileyGif,
  ExcellentSmileyGif,
  GoodSmileyGif,
  TerribleSmileyGif,
} from '../../../../assets/gifs';
import {
  BadSmileyDisabledIcon,
  BadSmileyIcon,
  CSMIcon,
  ClaimsAndSupportIcon,
  EndorsementsIcon,
  ExcellentSmileyDisabledIcon,
  ExcellentSmileyIcon,
  GoodSmileyDisabledIcon,
  GoodSmileyIcon,
  LoopHealthIcon,
  MedicalAdvisorsIcon,
  TerribleSmileyDisabledIcon,
  TerribleSmileyIcon,
  WellnessServicesIcon,
} from '../../../../assets/images';

export const feedbackData = [
  {
    name: 'claimsAndSupport',
    icon: ClaimsAndSupportIcon,
    label: 'Claims and Support',
    question:
      "How's our help with claims? (helping with documents, talking to TPAs and insurers, providing updates)",
    required: true,
    hasQnA: false,
  },
  {
    name: 'endorsements',
    icon: EndorsementsIcon,
    label: 'Endorsements',
    question:
      'How would you rate our performance in adding, editing,  deleting your employees in a timely manner?',
    required: true,
    hasQnA: false,
  },
  {
    name: 'medicalAdvisors',
    icon: MedicalAdvisorsIcon,
    label: 'Medical Advisors',
    question:
      'How would you rate our medical advisor chats and doctor consultations for your employees?',
    required: true,
    hasQnA: false,
  },
  {
    name: 'csm',
    icon: CSMIcon,
    label: 'CSM',
    question:
      'What is your satisfaction level with dedicated CSM who manages your account and answers your queries?',
    required: true,
    hasQnA: false,
  },
  {
    name: 'wellnessServices',
    icon: WellnessServicesIcon,
    label: 'Wellness Services',
    question:
      'How would you rate our overall wellness services (Webinars/Newsletters/Camps/Engagements)?',
    required: true,
    hasQnA: false,
  },
  {
    name: 'loophealth',
    icon: LoopHealthIcon,
    label: 'Overall Loop Health',
    question:
      'How would you rate Loop overall as a benefits provider for your organisation?',
    required: true,
    hasQnA: false,
  },
  {
    name: 'feedbackOrSuggestions',
    icon: '',
    label: 'Feedback or Suggestions',
    question: 'Any other feedback or suggestions',
    required: false,
    hasQnA: false,
  },
];

export const emojiData = [
  {
    value: 1,
    gif: TerribleSmileyGif,
    icon: TerribleSmileyDisabledIcon,
    label: 'Terrible',
  },
  { value: 2, gif: BadSmileyGif, icon: BadSmileyDisabledIcon, label: 'Bad' },
  { value: 3, gif: GoodSmileyGif, icon: GoodSmileyDisabledIcon, label: 'Good' },
  {
    value: 4,
    gif: ExcellentSmileyGif,
    icon: ExcellentSmileyDisabledIcon,
    label: 'Excellent',
  },
];

export const SELECTED_EMOJI_INDEXED_ARRAY = [
  {},
  {
    icon: TerribleSmileyIcon,
    label: 'TERRIBLE',
  },
  {
    icon: BadSmileyIcon,
    label: 'BAD',
  },
  {
    icon: GoodSmileyIcon,
    label: 'GOOD',
  },
  {
    icon: ExcellentSmileyIcon,
    label: 'EXCELLENT',
  },
  {
    label: 'NA',
  },
  {
    label: 'NA',
  },
];

export const FeedbackQnANames = feedbackData
  .filter((item) => item.hasQnA)
  .map((item) => item.name);
export const FeedbackOptionalFieldNames = feedbackData
  .filter((item) => !item.required)
  .map((item) => item.name);
